import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import SpreadFormatter from '@/common/helpers/SpreadFormatter';
import { SetupPresetGroupType } from '@/media/entity/SetupPresetGroup';
import MetricScaleContainer from '@/metric/components/MetricScaleContainer/MetricScaleContainer';
import SpreadMetricBar from '@/metric/components/SpreadMetricBar/SpreadMetricBar';
import SpreadV2Label from '@/metric/components/SpreadV2FullLabel/SpreadV2FullLabel';
import Diamond from '@/product/entity/Diamond';
export const FIELD_SPREAD = {
    key: 'spread',
    label: 'Spread v2',
    fullLabel: _jsx(SpreadV2Label, {}),
    type: FieldType.Metric,
    spGroupId: SetupPresetGroupType.Office,
    hasAbsoluteValue: true,
    icon: 'metrics-spread',
    getter(p) {
        if (p instanceof Diamond && p.spreadMetric) {
            return SpreadFormatter.format(p.spreadMetric.ct, 2, 'ct');
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.spreadMetric) {
            return p.spreadMetric.ct.toFixed(2);
        }
        return null;
    },
    decorator: (_, p, { ratio, size, label, icon, metricDataDecorator } = {}) => {
        if (p instanceof Diamond) {
            return (_jsx(SpreadMetricBar, { cutShapeId: p.cutShapeId, metric: p.spreadMetric, metricDataDecorator: metricDataDecorator, ratio: ratio, children: (scale, abs) => (_jsx(MetricScaleContainer, { abs: abs, icon: icon, isMetricCalculated: Boolean(p.spreadMetric), label: label, scale: scale, size: size })) }));
        }
        return null;
    },
    iteratee(p) {
        if (p instanceof Diamond && p.spreadMetric) {
            return p.spreadMetric.ct;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
