export function flattenTree(items) {
    return flatten(items);
}
function flatten(items, parentId = null, depth = 0) {
    return items.reduce((acc, item, index) => {
        const flattened = {
            id: item.id,
            parentId,
            canHasChildren: true,
            original: item,
            depth,
            collapsed: !!item.collapsed,
            editable: item.type !== 'appSection',
            hasGroupChildren: !!item.children && item.children.length > 0,
            index,
        };
        const flattenedChildren = flatten(item.children ?? [], item.id, depth + 1);
        return [...acc, flattened, ...flattenedChildren];
    }, []);
}
