import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import ReactQuill from 'react-quill';
import { observer } from 'mobx-react';
import cls from 'classnames';
import styles from './RichEditor.module.less';
export var RichEditorTheme;
(function (RichEditorTheme) {
    RichEditorTheme["Dark"] = "dark";
    RichEditorTheme["White"] = "white";
})(RichEditorTheme || (RichEditorTheme = {}));
function RichEditor(props, ref) {
    const { theme = RichEditorTheme.Dark, hideToolbar, disabled = false, className, ...rest } = props;
    return (_jsx(ReactQuill, { ref: ref, formats: ['bold', 'italic', 'underline', 'strike', 'list', 'blockquote', 'list', 'bullet', 'link', 'image'], modules: {
            toolbar: hideToolbar
                ? null
                : [['bold', 'italic', 'link', 'blockquote'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']],
        }, className: cls({ [styles.darkTheme]: theme === RichEditorTheme.Dark }, className), theme: "snow", readOnly: disabled, ...rest }));
}
export default observer(forwardRef(RichEditor));
