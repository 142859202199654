// extracted by mini-css-extract-plugin
var _1 = "base-link";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "emptyPlaceholder";
var _10 = "product-block-grid";
var _11 = "product-block-subgrid";
var _12 = "reset-button";
var _13 = "1200px";
var _14 = "992px";
var _15 = "1366px";
var _16 = "768px";
var _17 = "1475px";
var _18 = "576px";
var _19 = "1600px";
var _1a = "320px";
var _1b = "2500px";
var _1c = "single-page-responsive-font-size";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "emptyPlaceholder", _10 as "product-block-grid", _11 as "product-block-subgrid", _12 as "reset-button", _13 as "screenlg", _14 as "screenmd", _15 as "screenml", _16 as "screensm", _17 as "screenxlg", _18 as "screenxs", _19 as "screenxxlg", _1a as "screenxxs", _1b as "screenxxxlg", _1c as "single-page-responsive-font-size" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "emptyPlaceholder": _f, "product-block-grid": _10, "product-block-subgrid": _11, "reset-button": _12, "screenlg": _13, "screenmd": _14, "screenml": _15, "screensm": _16, "screenxlg": _17, "screenxs": _18, "screenxxlg": _19, "screenxxs": _1a, "screenxxxlg": _1b, "single-page-responsive-font-size": _1c }
