import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createConfirmation } from 'react-confirm';
import PublicCollectionConfirmationContent from '../../collection/components/PublicCollectionConfirmationContent/PublicCollectionConfirmationContent';
import { PRIMARY, QUATERNARY, TERTIARY } from '../components/Button/Button';
import Confirmation from '../components/inputs/Confirmation/Confirmation';
const defaultConfirmation = createConfirmation(Confirmation);
export function confirm(confirmation, options = {}) {
    return defaultConfirmation({ confirmation, ...options });
}
export default class ConfirmationFactory {
    static makeDeleteConfirmation(entityName, isPlural = false) {
        return confirm(`This action cannot be undone. This will permanently delete the ${entityName}${isPlural ? 's' : ''}.`, {
            title: `Delete ${isPlural ? 'these' : 'this'} ${entityName}${isPlural ? 's' : ''}?`,
            okLabel: 'Delete',
            okColor: TERTIARY,
        });
    }
    static makePublicCollectionConfirmation() {
        return confirm(_jsx(PublicCollectionConfirmationContent, {}), {
            title: 'Some products are hidden',
            okLabel: 'Publish Anyway',
            okColor: PRIMARY,
        });
    }
    static makeB2BIdChangeConfirmation() {
        return confirm('Are you going to change b2bSid?', {
            title: 'Are you sure?',
        });
    }
    static makeSKUChangeConfirmation() {
        return confirm('SKU was changed. Any previously shared link to this product will not be available after applying changes.', {
            title: 'Sensitive data changing confirmation',
        });
    }
    static makeMediaSourceDeleteConfirmation() {
        return confirm('This action cannot be undone. This will permanently delete the media source.', {
            title: 'Remove this media source file?',
            okLabel: 'Delete',
            okColor: TERTIARY,
        });
    }
    static makeDropChangesConfirmation() {
        return confirm('Your changes will be dropped', {
            title: 'Sensitive data changing confirmation',
        });
    }
    static makeChangeCutShapeConfirmation() {
        return confirm('You have an open full access to the cut model data', {
            title: 'Attention!',
            showCancelButton: false,
        });
    }
    static makeProjectRenderingConfirmation(productCount) {
        return confirm(`Do you want to render ${productCount} product(s)?`, {
            title: 'Are you sure?',
        });
    }
    static makeDeleteDriveProjectConfirmation(title) {
        return confirm(`This action cannot be undone. This will permanently delete ${title}`, {
            title: 'Are you sure?',
            okLabel: 'Delete',
            okColor: TERTIARY,
        });
    }
    static makeDeleteFromDriveProjectConfirmation(title) {
        return confirm(`This action cannot be undone. This will permanently delete ${title} from multistage project`, {
            title: 'Are you sure?',
            okLabel: 'Delete',
            okColor: TERTIARY,
        });
    }
    static makeDeleteDriveItemConfirmation(title) {
        return confirm(`This action cannot be undone. This will permanently delete ${title}`, {
            title: 'Are you sure?',
            okLabel: 'Delete',
            okColor: TERTIARY,
        });
    }
    static makePrivateDriveProjectConfirmation() {
        return confirm(_jsxs("div", { children: [_jsx("p", { children: "This multistage project will become private. Associated products and projects will remain public." }), _jsx("p", { children: "Please adjust their access permissions manually if necessary." })] }), {
            title: 'Confirm Private Access',
            okColor: PRIMARY,
        });
    }
    static makeSelectSolutionConfirmation(solutionId) {
        return confirm(_jsxs("div", { children: [_jsx("p", { children: "Only one Gold Star may be set at time!" }), _jsxs("p", { children: [_jsx("span", { children: "To set Gold Star to this plan, first remove it from plan " }), _jsxs("span", { children: ["\"", solutionId, "\""] })] })] }), {
            title: `Set Gold Star mark`,
            okColor: PRIMARY,
            showCancelButton: false,
        });
    }
    static makeRemoveMilestoneConfirmation() {
        return confirm(`This action cannot be undone. This will permanently delete milestone from drive-project`, {
            title: 'Are you sure?',
            okLabel: 'Delete',
            okColor: TERTIARY,
        });
    }
    static makeCancelTaskConfirmation() {
        return confirm(`This action cannot be undone. If you cancel this task, you can run it again, but it will be placed to the end of the queue.`, {
            title: 'Confirm task cancellation',
            okLabel: 'Confirm cancellation',
            okColor: QUATERNARY,
            cancelLabel: 'Keep task running',
            wide: true,
        });
    }
}
