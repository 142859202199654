import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './MetricScaleContainer.less';
import Icon from '../../../common/components/Icon/Icon';

export const SIZE_XSMALL = 'xs';
export const SIZE_SMALL = 's';
export const SIZE_MEDIUM = 'm';
export const SIZE_LARGE = 'l';

export default class MetricScaleContainer extends Component {
  static propTypes = {
    scale: PropTypes.node.isRequired,
    label: PropTypes.node,
    icon: PropTypes.string,
    abs: PropTypes.string,
    size: PropTypes.string,
    isMetricCalculated: PropTypes.bool,
  };

  static defaultProps = {
    label: null,
    icon: null,
    abs: '—',
    size: SIZE_MEDIUM,
    isMetricCalculated: true,
  };

  render() {
    if (this.props.size === SIZE_XSMALL) {
      return (
        <div className={styles.metricScaleContainerXSmall}>
          <div className={styles.metricScaleContainerXSmallABS} aria-label="Absolute Value">
            {this.props.abs}
          </div>
          <div className={styles.metricScaleContainerXSmallScale}>
            {this.props.scale}
          </div>
        </div>
      );
    }

    if (this.props.size === SIZE_SMALL) {
      return (
        <div className={`${styles.metricScaleContainerSmall} ${this.props.isMetricCalculated ? styles.metricScaleContainerActive : styles.metricScaleContainerInactive}`}>
          <div className={styles.metricScaleContainerSmallTitle}>
            <div className={styles.metricScaleContainerSmallIcon}>
              <Icon name={this.props.icon} />
            </div>

            <span className={styles.metricScaleContainerLabel}>{this.props.label}</span>

            <span className="ml-auto" aria-label="Absolute Value">{this.props.abs}</span>
          </div>

          {this.props.scale}
        </div>
      );
    }

    if (this.props.size === SIZE_MEDIUM) {
      return (
        <div className={`${styles.metricScaleContainerMedium} ${this.props.isMetricCalculated ? styles.metricScaleContainerActive : styles.metricScaleContainerInactive}`}>
          <div className={styles.metricScaleContainerMediumWrapper}>
            <div className={styles.metricScaleContainerMediumTitle}>
              <div className={styles.metricScaleContainerMediumIcon}>
                <Icon name={this.props.icon} />
              </div>

              <span className={styles.metricScaleContainerLabel}>{this.props.label}</span>

              <span className="ml-auto" aria-label="Absolute Value">{this.props.abs}</span>
            </div>

            <div className={styles.metricScaleContainerMediumScale}>
              {this.props.scale}
            </div>
          </div>
        </div>
      );
    }

    if (this.props.size === SIZE_LARGE) {
      return (
        <div className={`${styles.metricScaleContainerLarge} ${this.props.isMetricCalculated ? styles.metricScaleContainerActive : styles.metricScaleContainerInactive}`}>
          <div className={styles.metricScaleContainerLargeIcon}>
            <Icon name={this.props.icon} />
          </div>
          <div className={styles.metricScaleContainerLargeTitle}>
            {this.props.label}
          </div>
          <div className={styles.metricScaleContainerLargeABS} aria-label="Absolute Value">
            {this.props.abs}
          </div>
          <div className={styles.metricScaleContainerLargeScale}>
            {this.props.scale}
          </div>
        </div>
      );
    }

    return null;
  }
}
