import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import cls from 'classnames';
import styles from './EmptyPlaceholder.less';
export const TEXT_DEFAULT = 'Nothing found on your request. Try to change search parameters';
export const TEXT_EMPTY_COLLECTION = 'This collection is empty';
export const TEXT_EMPTY_PROJECT = 'This project does not have solutions';
export const TEXT_COMMON = 'Nothing found on your request';
export const NO_MULTI_PROJECTS_FIT_USER_FILTER_TEXT = 'No items meet the current filter criteria';
export const NO_MULTI_PROJECTS_TEXT = 'You don’t have any items';
function EmptyPlaceholder(props) {
    const { className, children = TEXT_DEFAULT } = props;
    return (_jsx("div", { "aria-label": "Empty placeholders", className: cls(styles.emptyPlaceholder, className), children: children }));
}
export default memo(EmptyPlaceholder);
