import { TYPE_ROUGH } from '@/product/constants/productTypes';
import Diamond from '@/product/entity/Diamond';
import Rough from '@/product/entity/Rough';
export function getProductShapeIcon(product) {
    if (product instanceof Diamond) {
        return product.parentCutShapeId;
    }
    if (product instanceof Rough) {
        return TYPE_ROUGH;
    }
    return product.type;
}
