import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import cls from 'classnames';
import styles from './Checkbox.less';
export const BLACK_COLOR = 'black';
export const WHITE_COLOR = 'white';
export const SPECTRUM_COLOR = 'spectrum';
function Checkbox(props) {
    const { type = 'checkbox', color = WHITE_COLOR, name, value, label, onChange, className, labelClassName, disabled, ambient, background, ...rest } = props;
    function onClickHandler(e) {
        onChange?.(e, name);
    }
    return (_jsxs("label", { className: cls(styles.fancyCheckbox, `${styles.fancyCheckbox}--${type}`, {
            [`${styles.fancyCheckbox}--ambient`]: Boolean(ambient),
            [styles.fancyCheckboxBlack]: color === BLACK_COLOR,
            [styles.fancyCheckboxSpectrum]: color === SPECTRUM_COLOR,
            [styles.fancyCheckboxDisabled]: disabled,
        }, className), children: [_jsx("input", { disabled: disabled, name: name, onClick: onClickHandler, readOnly: true, type: type, value: value, ...rest }), _jsx("span", { className: "checkmark", style: { backgroundColor: background }, children: ambient && _jsx("span", { className: "ambientDash" }) }), label && (_jsx("div", { className: cls(styles.fancyCheckboxLabel, { [styles.fancyCheckboxLabelDisabled]: disabled }, labelClassName), children: label }))] }));
}
export default memo(Checkbox);
