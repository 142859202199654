import { jsx as _jsx } from "react/jsx-runtime";
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import DeadZoneMetricBar from '@/metric/components/DeadZoneMetricBar/DeadZoneMetricBar';
import MetricScaleContainer from '@/metric/components/MetricScaleContainer/MetricScaleContainer';
import Diamond from '@/product/entity/Diamond';
export const FIELD_DEAD_ZONE = {
    key: 'dz',
    label: 'Dead zones',
    fullLabel: 'Dead zones',
    type: FieldType.Metric,
    icon: 'deadzone',
    getter(p) {
        if (p instanceof Diamond && p.deadZoneMetric) {
            return p.deadZoneMetric.h;
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.deadZoneMetric) {
            return Number(p.deadZoneMetric.val);
        }
        return null;
    },
    decorator: (_, p, { ratio, size, label, icon } = {}) => {
        if (p instanceof Diamond) {
            return (_jsx(DeadZoneMetricBar, { metric: p.deadZoneMetric, ratio: ratio, children: (scale, abs) => _jsx(MetricScaleContainer, { size: size, icon: icon, label: label, scale: scale, abs: abs }) }));
        }
        return null;
    },
    iteratee(p) {
        if (p instanceof Diamond && p.deadZoneMetric) {
            return p.deadZoneMetric.val;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
