import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { withStores } from '@/common/hoc/withStores';
import Notifier from '@/common/services/Notifier';
import DriveItemModalFooter from '@/drive/components/DriveItemModalFooter/DriveItemModalFooter';
import { TreeItemModal } from '@/drive/components/TreeItemModal/TreeItemModal';
import TreeItemTitle from '@/drive/components/TreeItemModal/TreeItemTitle/TreeItemTitle';
import { getModalTitle } from '@/drive/drivePrototype/components/DriveItemModal/DriveItemModal.utils';
import DriveItemModalForm from '@/drive/drivePrototype/components/DriveItemModal/DriveItemModalForm/DriveItemModalForm';
import DriveItemModalTree from '@/drive/drivePrototype/components/DriveItemModal/DriveItemModalTree/DriveItemModalTree';
import DriveItemModalTreeStore, { useDriveItemModalTreeStore, } from '@/drive/drivePrototype/components/DriveItemModal/DriveItemModalTree/DriveItemModalTreeStore';
import { getDriveItemId } from '@/drive/drivePrototype/components/DriveItemModal/DriveItemModalTree/DriveItemModalTreeStore.utils';
import { useDriveItemModalForm } from '@/drive/drivePrototype/components/DriveItemModal/useDriveItemModalForm';
import DriveItemPreview from '@/drive/drivePrototype/components/DriveItemPreview/DriveItemPreview';
import DriveItemModalStore, { useDriveItemModalStore } from '@/drive/drivePrototype/stores/DriveItemModalStore';
import { createEmptyForm } from '@/drive/drivePrototype/types/driveItemModalFormData';
import { MediaSize } from '@/media/constants/sizeCodes';
import styles from './DriveItemModal.module.less';
function DriveItemModal(props) {
    const { config, onClose, onSuccess, onBack, usePortal } = props;
    const { forwardConfig, action } = config ?? {};
    const driveItemModalStore = useDriveItemModalStore();
    const driveItemModalTreeStore = useDriveItemModalTreeStore();
    useEffect(() => {
        if (forwardConfig) {
            driveItemModalTreeStore.refresh();
        }
        else {
            driveItemModalTreeStore.reset();
        }
    }, [forwardConfig]);
    const initialForm = useMemo(() => (forwardConfig ? forwardConfig.getForm() : createEmptyForm()), [forwardConfig]);
    const { values, errors, handleSubmit, setFieldValue } = useDriveItemModalForm(async (formValues) => {
        if (!forwardConfig || !action) {
            return;
        }
        if (!driveItemModalTreeStore.selectedId) {
            Notifier.warn('Select Folder');
            return;
        }
        if (driveItemModalTreeStore.newItem) {
            await driveItemModalTreeStore.finishCreation();
        }
        const { success } = await driveItemModalStore.submit(forwardConfig, formValues, getDriveItemId(driveItemModalTreeStore.selectedId), action);
        if (success) {
            onClose?.();
            onSuccess?.();
        }
    }, initialForm, forwardConfig?.formDisabled());
    return (_jsx(TreeItemModal, { usePortal: usePortal, isShowed: !!forwardConfig, onClose: onClose, title: (_jsx(TreeItemTitle, { title: action ? getModalTitle(action) : '', filter: driveItemModalTreeStore.filter, onFilterChange: driveItemModalTreeStore.changeFilter, filterShown: true, onBack: onBack })), treeShown: true, previewShown: false, treeContent: _jsx(DriveItemModalTree, {}), formContent: (_jsxs("div", { className: styles.rightContainer, children: [forwardConfig && _jsx(DriveItemPreview, { preview: forwardConfig.getPreview(), mediaSize: MediaSize.Small }), _jsx(DriveItemModalForm, { values: values, errors: errors, disabled: forwardConfig?.formDisabled(), setFieldValue: setFieldValue, onReset: onClose, onSubmit: handleSubmit })] })), footerContent: _jsx(DriveItemModalFooter, { addText: "Add Folder", onAdd: driveItemModalTreeStore.startCreation }) }));
}
export default withStores(() => ({
    driveItemModalStore: new DriveItemModalStore(),
    driveItemModalTreeStore: new DriveItemModalTreeStore(),
}), observer(DriveItemModal));
