import { DriveItemType } from '@/drive/drivePrototype/entities/DriveItem';
export var ForwardFolderAction;
(function (ForwardFolderAction) {
    ForwardFolderAction[ForwardFolderAction["Add"] = 1] = "Add";
    ForwardFolderAction[ForwardFolderAction["Move"] = 2] = "Move";
})(ForwardFolderAction || (ForwardFolderAction = {}));
export function getUniqueDestinationId(destination) {
    if (destination.type === DriveItemType.Folder) {
        return `${destination.type}:${destination.id}:${destination.action}`;
    }
    return `${destination.type}:${destination.id}`;
}
