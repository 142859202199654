import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import cls from 'classnames';
import Button, { PRIMARY } from '@/common/components/Button/Button';
import { SIZE_MEDIUM } from '@/common/components/Label/Label';
import styles from './DriveItemModalFooter.module.less';
function DriveItemModalFooter(props) {
    const { onAdd, addText } = props;
    const canAdd = addText && onAdd;
    return (_jsxs("div", { className: cls(styles.driveItemModalFooter, { [styles.withAddButton]: canAdd }), children: [canAdd && (_jsx(Button, { "aria-label": "drive-item-add", className: styles.button, onClick: onAdd, size: SIZE_MEDIUM, children: addText })), _jsxs("div", { className: styles.rightGroup, children: [_jsx(Button, { "aria-label": "drive-item-cancel", form: "drive-modal-form", type: "reset", className: styles.button, children: "Cancel" }), _jsx(Button, { className: styles.button, "aria-label": "drive-item-save", form: "drive-modal-form", type: "submit", color: PRIMARY, size: SIZE_MEDIUM, children: "Save" })] })] }));
}
export default observer(DriveItemModalFooter);
