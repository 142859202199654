import { jsx as _jsx } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './Action.module.less';
export const Action = forwardRef(({ active, className, cursor, style, ...props }, ref) => {
    return (_jsx("button", { ref: ref, ...props, className: classNames(styles.action, className), tabIndex: 0, style: {
            ...style,
            cursor,
            '--fill': active?.fill,
            '--background': active?.background,
        } }));
});
