import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FancyInput from '@/common/components/inputs/FancyInput/FancyInput';
import ForwardDestinationList from '@/drive/drivePrototype/components/Forwarder/ForwardModal/ForwardDestinationList/ForwardDestinationList';
import ForwardModalActions from '../ForwardModalActions/ForwardModalActions';
import { useForwardModalStore } from '../ForwardModalStore';
import styles from './ForwardModalBodyMd.module.less';
function ForwardModalBodyMd(props) {
    const { filter, changeFilter, hasAnyDestinations } = useForwardModalStore();
    const { config, onDestinationSelect, openDriveItemModal, openAddToCollectionModal, addToSpectrum, onQrClick } = props;
    return (_jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.rightContainer, children: [hasAnyDestinations && (_jsx("div", { className: styles.inputContainer, children: _jsx(FancyInput, { startIcon: "search", placeholder: "Find in history", value: filter, onChange: changeFilter }) })), _jsx("div", { className: styles.listContainer, children: _jsx(ForwardDestinationList, { onSelect: onDestinationSelect }) })] }), _jsx(ForwardModalActions, { config: config, openDriveItemModal: openDriveItemModal, openAddToCollectionModal: openAddToCollectionModal, addToSpectrum: addToSpectrum, onQrClick: onQrClick })] }));
}
export default ForwardModalBodyMd;
