import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import Button from '@/common/components/Button/Button';
import browserHistory from '@/common/services/history';
import styles from './ForwardNotification.module.less';
// browserHistory.push is used here because notifications are shown outside react-router context
function ForwardNotification(props) {
    const { text, buttonText = 'Open', url } = props;
    return (_jsxs("div", { className: styles.container, children: [text, _jsx(Button, { active: true, size: "s", className: styles.navigationButton, onClick: () => browserHistory.push(url), children: buttonText })] }));
}
export default observer(ForwardNotification);
