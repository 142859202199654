import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, Fragment, memo } from 'react';
import cls from 'classnames';
import Button, { SIZE_XSMALL } from '@/common/components/Button/Button';
import Icon from '@/common/components/Icon/Icon';
import styles from './FancyInput.module.less';
const CLASS_TEXT_COLOR_MAP = {
    error: styles.fancyInputErrorText,
    success: styles.fancyInputSuccessText,
};
const FancyInput = forwardRef((props, ref) => {
    const { value, defaultValue, onChange, onBlur, onClear, appearance, helperText, normalize, endIcon, startIcon, onClickEndIcon, onClickStartIcon, contentClassName, ...rest } = props;
    let sanitizedValue = value ?? defaultValue;
    if ((value === null || value === undefined) && defaultValue === null) {
        sanitizedValue = '';
    }
    function onBlurHandler(e) {
        onBlur?.(e.target.value, e, normalize);
    }
    function onChangeHandler(e) {
        onChange?.(e.target.value, e, normalize);
    }
    return (_jsxs(Fragment, { children: [_jsxs("div", { className: cls(styles.fancyInput, appearance && CLASS_TEXT_COLOR_MAP[appearance], {
                    [styles.fancyInputHasIcon]: endIcon || startIcon,
                }, contentClassName), children: [startIcon && (_jsx(Button, { className: styles.fancyInputIcon, color: "transparent", onClick: onClickStartIcon, withIcon: true, children: _jsx(Icon, { name: startIcon }) })), _jsx("input", { ref: ref, ...rest, onBlur: onBlurHandler, onChange: onChangeHandler, value: sanitizedValue }), onClear && value && (_jsx("div", { className: styles.clear, children: _jsx(Button, { color: "transparent", onClick: onClear, size: SIZE_XSMALL, withIcon: true, children: _jsx(Icon, { name: "close" }) }) })), endIcon && (_jsx(Button, { className: styles.fancyInputIcon, color: "transparent", onClick: onClickEndIcon, withIcon: true, children: _jsx(Icon, { name: endIcon }) }))] }), helperText && _jsx("div", { className: appearance && CLASS_TEXT_COLOR_MAP[appearance], children: helperText })] }));
});
export default memo(FancyInput);
