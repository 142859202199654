import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { withStores } from '@/common/hoc/withStores';
import Notifier from '@/common/services/Notifier';
import DriveItemModalFooter from '@/drive/components/DriveItemModalFooter/DriveItemModalFooter';
import { TreeItemModal } from '@/drive/components/TreeItemModal/TreeItemModal';
import TreeItemTitle from '@/drive/components/TreeItemModal/TreeItemTitle/TreeItemTitle';
import DriveItemModalForm from '@/drive/drivePrototype/components/DriveItemModal/DriveItemModalForm/DriveItemModalForm';
import { useDriveItemModalForm } from '@/drive/drivePrototype/components/DriveItemModal/useDriveItemModalForm';
import DriveItemPreview from '@/drive/drivePrototype/components/DriveItemPreview/DriveItemPreview';
import AddToCollectionModalList from '@/drive/drivePrototype/components/Forwarder/AddToCollectionModal/AddToCollectionModalList/AddToCollectionModalList';
import AddToCollectionModalListStore, { useAddToCollectionModalListStore, } from '@/drive/drivePrototype/components/Forwarder/AddToCollectionModal/AddToCollectionModalList/AddToCollectionModalListStore';
import AddToCollectionModalStore, { useAddToCollectionModalStore, } from '@/drive/drivePrototype/components/Forwarder/AddToCollectionModal/AddToCollectionModalStore';
import { createEmptyForm } from '@/drive/drivePrototype/types/driveItemModalFormData';
import { MediaSize } from '@/media/constants/sizeCodes';
import styles from './AddToCollectionModal.module.less';
function AddToCollectionModal(props) {
    const { config, usePortal, onBack, onClose } = props;
    const addToCollectionModalStore = useAddToCollectionModalStore();
    const listStore = useAddToCollectionModalListStore();
    const initialForm = useMemo(() => (config ? config.getForm() : createEmptyForm()), [config]);
    useEffect(() => {
        const params = config?.getCollectionReqData();
        if (params) {
            listStore.refresh(params.ids.length === 1 ? params.ids[0] : null, params.type);
        }
        else {
            listStore.reset();
        }
    }, [config]);
    const { values, errors, handleSubmit, setFieldValue } = useDriveItemModalForm(async () => {
        if (addToCollectionModalStore.isSubmitting) {
            return;
        }
        if (!listStore.selectedId) {
            Notifier.warn('Select Collection');
            return;
        }
        if (listStore.newCollectionTitle) {
            await listStore.finishCreation();
        }
        await addToCollectionModalStore.submit(config, values, listStore.selectedId);
        onClose();
    }, initialForm, !!config?.formDisabled());
    return (_jsx(TreeItemModal, { isShowed: !!config, onClose: onClose, title: (_jsx(TreeItemTitle, { filter: listStore.filter, onFilterChange: listStore.changeFilter, filterShown: true, title: "Add to Collection", onBack: onBack })), treeShown: true, treeContent: _jsx(AddToCollectionModalList, {}), usePortal: usePortal, footerContent: _jsx(DriveItemModalFooter, { addText: "Add Collection", onAdd: listStore.startCreation }), formContent: (_jsxs("div", { className: styles.rightContainer, children: [config && _jsx(DriveItemPreview, { preview: config.getPreview(), mediaSize: MediaSize.Small }), _jsx(DriveItemModalForm, { values: values, errors: errors, disabled: config?.formDisabled(), setFieldValue: setFieldValue, onSubmit: handleSubmit, onReset: onClose })] })) }));
}
export default withStores(({ driveCollectionsStore }) => ({
    addToCollectionModalStore: new AddToCollectionModalStore(),
    addToCollectionModalListStore: new AddToCollectionModalListStore(driveCollectionsStore),
}), observer(AddToCollectionModal));
