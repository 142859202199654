import { DriveItemType } from '@/drive/drivePrototype/entities/DriveItem';
export function createEmptyForm() {
    return {
        title: '',
        description: '',
        url: '',
    };
}
export function createFormForDriveItem(item) {
    return {
        ...createEmptyForm(),
        title: item.title ?? '',
        description: item.description ?? '',
        url: item.type === DriveItemType.Url ? item.url : '',
    };
}
