var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from 'mobx';
import useStore from '@/common/hooks/useStore';
import Notifier from '@/common/services/Notifier';
import { DriveAPI } from '@/drive/drivePrototype/api/DriveAPI';
import AddToCollectionModalStore from '@/drive/drivePrototype/components/Forwarder/AddToCollectionModal/AddToCollectionModalStore';
import { DriveItemType } from '@/drive/drivePrototype/entities/DriveItem';
import { DriveType } from '@/drive/drivePrototype/entities/DriveType';
import { ForwardFolderAction, } from '@/drive/drivePrototype/entities/ForwardDestinationListData';
import ForwardDestinationStorage from '@/drive/drivePrototype/services/ForwardDestinationStorage';
import { ForwardNotificationService } from '@/drive/drivePrototype/services/ForwardNotificationService';
import DriveItemModalStore from '@/drive/drivePrototype/stores/DriveItemModalStore';
import { fromDriveFolder } from '@/drive/drivePrototype/types/driveTreeItem';
import { flattenTree } from '@/drive/drivePrototype/utils/DriveTree.utils';
class ForwardModalStore {
    userStore;
    driveCollectionsStore;
    driveItemModalStore;
    addToCollectionModalStore;
    driveAPI;
    spectrumDriveAPI;
    hasAnyDestinations = false;
    driveTree = null;
    baseDestinations = null;
    filter = '';
    config = null;
    get isSubmitting() {
        return this.driveItemModalStore.isSubmitting;
    }
    get allDestinations() {
        const userNotForwardedAnythingYet = this.baseDestinations && this.baseDestinations.length === 0;
        if (userNotForwardedAnythingYet) {
            return [];
        }
        if (!this.baseDestinations || !this.driveCollectionsStore.collections || !this.driveTree || !this.config) {
            return null;
        }
        const foldersMap = flattenTree(this.driveTree.map(fromDriveFolder))
            .map((item) => item.original)
            .reduce((acc, item) => {
            acc.set(item.id, item);
            return acc;
        }, new Map());
        const collectionsMap = this.driveCollectionsStore.collections.reduce((acc, item) => {
            acc.set(item.id, item);
            return acc;
        }, new Map());
        return this.baseDestinations.flatMap((destination) => {
            const data = this.toDestinationListData(destination, foldersMap, collectionsMap, this.config);
            return data ? [data] : [];
        });
    }
    get destinations() {
        if (!this.allDestinations) {
            return null;
        }
        const filter = this.filter.trim().toLowerCase();
        if (filter.length === 0) {
            return this.allDestinations;
        }
        return this.allDestinations.filter((d) => d.title.includes(filter));
    }
    constructor(userStore, driveCollectionsStore, driveItemModalStore = new DriveItemModalStore(), addToCollectionModalStore = new AddToCollectionModalStore(), driveAPI = new DriveAPI(), spectrumDriveAPI = new DriveAPI(DriveType.CutwiseSpectrum)) {
        this.userStore = userStore;
        this.driveCollectionsStore = driveCollectionsStore;
        this.driveItemModalStore = driveItemModalStore;
        this.addToCollectionModalStore = addToCollectionModalStore;
        this.driveAPI = driveAPI;
        this.spectrumDriveAPI = spectrumDriveAPI;
    }
    changeFilter = (filter) => {
        this.filter = filter;
    };
    refresh = async (config) => {
        this.config = config;
        const destinations = ForwardDestinationStorage.get();
        if (!this.userStore.isAuthorized || destinations.length === 0) {
            this.baseDestinations = [];
            this.driveTree = [];
            this.hasAnyDestinations = false;
            return;
        }
        this.baseDestinations = destinations;
        this.hasAnyDestinations = true;
        if (this.userStore.user?.isFlagAllowed('allowDrive')) {
            this.driveTree = await this.driveAPI.fetchTree();
        }
        else {
            this.driveTree = [];
        }
        return this.driveCollectionsStore.refresh();
    };
    reset = () => {
        this.filter = '';
        this.baseDestinations = null;
        this.driveTree = null;
        this.config = null;
    };
    submit = async (config, form, destination) => {
        if (destination.type === DriveItemType.Folder) {
            return this.driveItemModalStore.submit(config, form, destination.id, destination.action);
        }
        else {
            await this.addToCollectionModalStore.submit(config, form, destination.id);
            return { success: true };
        }
    };
    addToSpectrum = async (config, form) => {
        const reqData = config.getSpectrumAddReqData();
        if (!reqData) {
            return { success: false };
        }
        const mappedReqData = reqData.map((item) => ({
            ...item,
            ...config.formToRequestData(form),
            parent: null,
        }));
        const res = await this.spectrumDriveAPI.batchCreate(mappedReqData);
        if (res.hasErrors()) {
            res.getErrors().forEach((error) => Notifier.error(error));
            return { success: false };
        }
        else {
            ForwardNotificationService.addToSpectrumSuccess(res.getItems()[0].id);
            return { success: true };
        }
    };
    toDestinationListData(destination, foldersMap, collectionsMap, config) {
        const folder = foldersMap.get(destination.id);
        const collection = collectionsMap.get(destination.id);
        const isFolderDestination = destination.type === DriveItemType.Folder && folder;
        const isCollectionDestination = destination.type === DriveItemType.Collection && collection;
        if (isFolderDestination && (destination.action !== ForwardFolderAction.Move || config.getDriveMoveReqData())) {
            return {
                id: destination.id,
                type: DriveItemType.Folder,
                action: destination.action,
                title: `${destination.action === ForwardFolderAction.Add ? 'Add to' : 'Move to'} ${folder.title}`,
            };
        }
        else {
            if (isCollectionDestination && config.addToCollectionEnabled(collection.type)) {
                return {
                    id: destination.id,
                    type: DriveItemType.Collection,
                    title: `Add to ${collection.title}`,
                };
            }
        }
        return null;
    }
}
__decorate([
    observable
], ForwardModalStore.prototype, "hasAnyDestinations", void 0);
__decorate([
    observable
], ForwardModalStore.prototype, "driveTree", void 0);
__decorate([
    observable
], ForwardModalStore.prototype, "baseDestinations", void 0);
__decorate([
    observable
], ForwardModalStore.prototype, "filter", void 0);
__decorate([
    observable
], ForwardModalStore.prototype, "config", void 0);
__decorate([
    computed
], ForwardModalStore.prototype, "allDestinations", null);
__decorate([
    computed
], ForwardModalStore.prototype, "destinations", null);
__decorate([
    action
], ForwardModalStore.prototype, "changeFilter", void 0);
__decorate([
    action
], ForwardModalStore.prototype, "refresh", void 0);
__decorate([
    action
], ForwardModalStore.prototype, "reset", void 0);
export const useForwardModalStore = () => useStore(ForwardModalStore);
export default ForwardModalStore;
