import {observable} from 'mobx';
import {
  TYPE_DIAMOND_COLORLESS,
  TYPE_DIAMOND_FANCY,
  TYPE_JEWELRY,
  TYPE_LGD_COLORLESS, TYPE_LGD_FANCY, TYPE_LGD_ROUGH, TYPE_ROUGH,
} from '../../product/constants/productTypes';
import { INTERNAL, securityManager } from '@/common/services/SecurityManager';

const PRODUCT_SORT_TYPES_MAP = {
  [TYPE_DIAMOND_COLORLESS]: TYPE_DIAMOND_COLORLESS,
  [TYPE_DIAMOND_FANCY]: TYPE_DIAMOND_FANCY,
  [TYPE_JEWELRY]: TYPE_JEWELRY,
  [TYPE_LGD_COLORLESS]: TYPE_LGD_COLORLESS,
  [TYPE_LGD_FANCY]: TYPE_LGD_FANCY,
  [TYPE_ROUGH]: TYPE_ROUGH,
  [TYPE_LGD_ROUGH]: TYPE_ROUGH,
};

const ONLY_INTERNAL_USER_SORT_FIELDS = new Set([
  'brilliance.gmc2',
  'brilliance.smc2',
  'brilliance.val2',
  'brilliance.rg2',
  'deadzones.val',
  'scintillation.val',
  'scintillation.rg',
]);

class SortRepository {
  /**
   * @type {[Object.<string, [Sort]>]}
   */
  @observable
  sortsByType;

  /**
   * @private
   * @param {Object} productTypeSpecificConstants
   * @param {[Object]} sortsData
   * @return {Object}
   */
  static modifyProductTypes(productTypeSpecificConstants, sortsData) {
    const result = {};
    Object.keys(PRODUCT_SORT_TYPES_MAP).forEach((productType) => {
      result[productType] = productTypeSpecificConstants[PRODUCT_SORT_TYPES_MAP[productType]].sorts
        .map(sortId => sortsData.find(sort => sort.id === sortId))
        .filter(s => Boolean(s));
    });

    return result;
  }

  /**
   * @param {{id: number, title: string, field: string}[]} sortsData
   * @param {Object} sortsByProductType
   * @return {*}
   */
  initSorts(sortsData, sortsByProductType) {
    this.sortsByType = SortRepository.modifyProductTypes(sortsByProductType, sortsData);
  }

  /**
   * @param {string} productType
   * @return {[Sort]}
   */
  getSortsByProductType(productType) {
    return this.sortsByType[productType]?.filter((sort) => {
      return securityManager.isGranted(INTERNAL) || !ONLY_INTERNAL_USER_SORT_FIELDS.has(sort.field);
    });
  }
}

export const sortRepository = new SortRepository();
