import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import EmptyPlaceholder from '@/common/components/EmptyPlaceholder/EmptyPlaceholder';
import Loader from '@/common/components/Loader/Loader';
import TreeItemInput from '@/drive/components/TreeItem/TreeItemInput/TreeItemInput';
import { useAddToCollectionModalListStore } from '@/drive/drivePrototype/components/Forwarder/AddToCollectionModal/AddToCollectionModalList/AddToCollectionModalListStore';
import CollectionListItem from '@/drive/drivePrototype/components/Forwarder/AddToCollectionModal/AddToCollectionModalList/CollectionListItem/CollectionListItem';
import styles from './AddToCollectionModalList.module.less';
function AddToCollectionModalList() {
    const { allCollections, filteredCollections, disabledCollectionIds, newCollectionTitle, isPendingCreation, setSelectedId, setNewCollectionTitle, selectedId, newCollectionId, finishCreation, } = useAddToCollectionModalListStore();
    if (!allCollections || !filteredCollections) {
        return _jsx(Loader, {});
    }
    if (filteredCollections.length === 0) {
        const text = allCollections.length === 0
            ? 'No Collections found, please create one'
            : 'No Collections match your search. Try a different keyword';
        return _jsx(EmptyPlaceholder, { children: text });
    }
    return (_jsxs("div", { className: styles.addToCollectionModalList, children: [newCollectionId && (_jsx(CollectionListItem, { title: (_jsx(TreeItemInput, { isPendingCreation: isPendingCreation, isSelected: selectedId === newCollectionId, value: newCollectionTitle, save: finishCreation, onChange: setNewCollectionTitle })), onClick: () => setSelectedId(newCollectionId), isSelected: selectedId === newCollectionId })), filteredCollections.map((c) => (_jsx(CollectionListItem, { onClick: () => setSelectedId(c.id), isSelected: selectedId === c.id, disabled: disabledCollectionIds.has(c.id), title: c.title }, c.id)))] }));
}
export default observer(AddToCollectionModalList);
