var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from 'mobx';
import useStore from '@/common/hooks/useStore';
import Notifier from '@/common/services/Notifier';
import { ServiceOrderStatus } from '@/conversion/constants/serviceOrderStatus';
import { ServiceOrder } from '@/conversion/entities/ServiceOrder';
import { userStore } from '@/user/stores/UserStore';
import ServicesAPI from '../api/ServicesAPI';
const FETCH_NEW_ORDERS_LIST_INTERVAL = 10000;
export default class ServicesStore {
    userStore;
    isCreateModalOpen = false;
    isFetchingOrders = false;
    isCreatingOrder = false;
    orderList = [];
    filter = {
        filename: '',
    };
    conversionAPI;
    requestOrderInterval;
    constructor(userStore) {
        this.userStore = userStore;
        this.conversionAPI = new ServicesAPI();
    }
    get isOrderListEmpty() {
        return this.orderList.length === 0;
    }
    get filteredOrderList() {
        return this.orderList.filter((order) => order.name.toLowerCase().includes(this.filter.filename.toLowerCase()));
    }
    fetchOrderList = async () => {
        this.isFetchingOrders = true;
        try {
            await this.getOrdersListRequest();
            clearInterval(this.requestOrderInterval);
            this.requestOrderInterval = setInterval(() => this.refetchOrdersListRequest(), FETCH_NEW_ORDERS_LIST_INTERVAL);
        }
        catch (e) {
            Notifier.error(e);
        }
        this.isFetchingOrders = false;
    };
    createOrder = (data) => {
        this.isCreatingOrder = true;
        return this.conversionAPI
            .createOrder(data)
            .then(() => this.getOrdersListRequest())
            .finally(() => {
            this.isCreatingOrder = false;
        });
    };
    openCreateModal = () => {
        if (this.userStore.isAuthorized) {
            this.isCreateModalOpen = true;
        }
        else {
            Notifier.warn('Log in required to use conversion service');
        }
    };
    closeCreateModal = () => {
        this.isCreateModalOpen = false;
    };
    setServicesFilter = (filter) => {
        this.filter = {
            ...this.filter,
            ...filter,
        };
    };
    dispose = () => {
        clearInterval(this.requestOrderInterval);
    };
    getOrdersListRequest = () => this.conversionAPI.getOrderList().then((res) => {
        if (!res?.content) {
            return;
        }
        this.orderList = res.content.map((order) => new ServiceOrder(order));
    });
    refetchOrdersListRequest = async () => {
        // Check if at least one order has "Processing" status, if yes - re-fetch list, if not - do nothing
        if (this.orderList?.some((order) => order.status === ServiceOrderStatus.Processing)) {
            await this.getOrdersListRequest();
        }
    };
}
__decorate([
    observable
], ServicesStore.prototype, "isCreateModalOpen", void 0);
__decorate([
    observable
], ServicesStore.prototype, "isFetchingOrders", void 0);
__decorate([
    observable
], ServicesStore.prototype, "isCreatingOrder", void 0);
__decorate([
    observable
], ServicesStore.prototype, "orderList", void 0);
__decorate([
    observable
], ServicesStore.prototype, "filter", void 0);
__decorate([
    computed
], ServicesStore.prototype, "isOrderListEmpty", null);
__decorate([
    computed
], ServicesStore.prototype, "filteredOrderList", null);
__decorate([
    action
], ServicesStore.prototype, "fetchOrderList", void 0);
__decorate([
    action
], ServicesStore.prototype, "createOrder", void 0);
__decorate([
    action
], ServicesStore.prototype, "openCreateModal", void 0);
__decorate([
    action
], ServicesStore.prototype, "closeCreateModal", void 0);
__decorate([
    action
], ServicesStore.prototype, "setServicesFilter", void 0);
export const useServicesStore = () => useStore(ServicesStore);
export const servicesStore = new ServicesStore(userStore);
