export class UserModel {
    id;
    roles;
    deny;
    b2b;
    lastName;
    firstName;
    username;
    email;
    phone;
    utcTimezone;
    locale;
    flags;
    settings;
}
export class User extends UserModel {
    constructor(modelData = {}) {
        super();
        Object.assign(this, modelData);
        this.flags = modelData?.flags || {};
        this.settings = modelData?.settings || {};
    }
    get isAnonymous() {
        return !this.id;
    }
    get b2bId() {
        return this.b2b?.id ?? null;
    }
    get b2bTitle() {
        return this.b2b?.title ?? null;
    }
    get getFullName() {
        if (this.firstName && this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }
        if (this.firstName) {
            return this.firstName;
        }
        if (this.lastName) {
            return this.lastName;
        }
        return '';
    }
    get getUserRenderName() {
        const userFullName = this.getFullName;
        if (userFullName) {
            return userFullName;
        }
        if (this.username) {
            return this.username;
        }
        return 'Unknown user';
    }
    isFlagAllowed(flagName) {
        return Boolean(this.flags?.[flagName]);
    }
}
