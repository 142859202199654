import { toBaseProductType } from '@/collection/entity/CollectionType';
import { DriveItemType } from '@/drive/drivePrototype/entities/DriveItem';
import { createEmptyForm } from '@/drive/drivePrototype/types/driveItemModalFormData';
import { getProductFieldsFromPinnedItem, getRequestDataFromPinnedItem, PinnedItemType, } from '@/drive/drivePrototype/types/pinnedItem';
export class PinnedItemsForwardConfig {
    pinnedItems;
    constructor(pinnedItems) {
        this.pinnedItems = pinnedItems;
    }
    getForm() {
        return createEmptyForm();
    }
    formDisabled() {
        return true;
    }
    getPreview() {
        return { type: DriveItemType.Folder };
    }
    addToCollectionEnabled(collectionType) {
        const reqData = this.getCollectionReqData();
        return reqData?.type === toBaseProductType(collectionType);
    }
    getCollectionReqData() {
        const productFields = this.pinnedItems.map(getProductFieldsFromPinnedItem).filter(Boolean);
        if (productFields.length === 0 || productFields.length !== this.pinnedItems.length) {
            return null;
        }
        const productType = productFields[0].productType;
        if (!productFields.every((item) => item.productType === productType)) {
            return null;
        }
        return {
            ids: productFields.map(({ productId }) => productId),
            type: productType,
        };
    }
    getDriveAddReqData() {
        return this.pinnedItems
            .map(getRequestDataFromPinnedItem)
            .map((item) => ({ ...item, id: undefined, copyFrom: item.id, type: item.type }));
    }
    getDriveMoveReqData() {
        if (this.pinnedItems.every((item) => item.type === PinnedItemType.DriveItem)) {
            return this.pinnedItems.map(getRequestDataFromPinnedItem).map((item) => ({ ...item, type: item.type }));
        }
        return null;
    }
    getUrl() {
        return null;
    }
    getSpectrumAddReqData() {
        return null;
    }
    getNativeShareConfig() {
        return null;
    }
    getMailUrlParams() {
        return null;
    }
    formToRequestData() {
        return {};
    }
}
