var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from 'mobx';
import { ROLE_CUSTOM_GRADER, ROLE_INTERNAL, ROLE_LABGROWN, ROLE_MANAGER, ROLE_METRICS_CONTROLLER, ROLE_NETBOX_USER, ROLE_RECUT_MANAGER, ROLE_RENDERER, ROLE_SUPER_ADMIN, } from '../constants/roles';
var SecurityAction;
(function (SecurityAction) {
    SecurityAction["GoToGrading"] = "goToGradingGranted";
    SecurityAction["CollectionEdit"] = "collectionEditGranted";
    SecurityAction["ProductEdit"] = "productPageEditGranted";
    SecurityAction["NetBox"] = "netboxGranted";
    SecurityAction["LGD"] = "lgdGranted";
    SecurityAction["Manager"] = "managerGranted";
    SecurityAction["Internal"] = "internalGranted";
    SecurityAction["ShareAccess"] = "shareAccessGranted";
    SecurityAction["Admin"] = "adminGranted";
    SecurityAction["ViewProductStatus"] = "viewProductStatusGranted";
    SecurityAction["Renderer"] = "rendererGranted";
    SecurityAction["RenderManager"] = "renderManagerGranted";
    SecurityAction["BasicGranted"] = "basisGranted";
    SecurityAction["MetricsControllerGranted"] = "metricsControllerGranted";
    SecurityAction["GradeProduct"] = "gradeProduct";
    SecurityAction["ManageProject"] = "manageProject";
})(SecurityAction || (SecurityAction = {}));
export const GO_TO_GRADING = SecurityAction.GoToGrading;
export const COLLECTION_EDIT = SecurityAction.CollectionEdit;
export const PRODUCT_EDIT = SecurityAction.ProductEdit;
export const NETBOX = SecurityAction.NetBox;
export const LGD = SecurityAction.LGD;
export const MANAGER = SecurityAction.Manager;
export const INTERNAL = SecurityAction.Internal;
export const SHARE_ACCESS = SecurityAction.ShareAccess;
export const ADMIN = SecurityAction.Admin;
export const VIEW_PRODUCT_STATUS = SecurityAction.ViewProductStatus;
export const RENDERER = SecurityAction.Renderer;
export const RENDER_MANAGER = SecurityAction.RenderManager;
export const BASIS_GRANTED = SecurityAction.BasicGranted;
export const METRICS_CONTROLLER_GRANTED = SecurityAction.MetricsControllerGranted;
export const GRADE_PRODUCT = SecurityAction.GradeProduct;
export const MANAGE_PROJECT = SecurityAction.ManageProject;
export const DENY_IMPORT_UPLOAD = 'import:upload';
export const DENY_DELETE_SOLUTION = 'solution:delete';
export const DENY_SHARE_PROJECT = 'project:acl';
export const DENY_SHARE_PRODUCT = 'product:acl';
export const DENY_DELETE_PROJECT = 'project:delete';
export const DENY_DELETE_PRODUCT = 'product:delete';
export const DENY_EDIT_PRODUCT = 'product:edit';
export const DENY_RENDER_PROJECT = 'project:render';
const ACTION_GRANTERS = {
    [METRICS_CONTROLLER_GRANTED]: ({ roles }) => Boolean(roles?.includes(ROLE_METRICS_CONTROLLER)),
    [BASIS_GRANTED]: ({ roles, b2bId }) => Boolean(roles?.includes(ROLE_INTERNAL) || b2bId === 59),
    [GO_TO_GRADING]: ({ roles }) => Boolean(roles?.includes(ROLE_CUSTOM_GRADER)),
    [COLLECTION_EDIT]: ({ id }, options) => Boolean(options?.checkingCollection?.user?.id === id),
    [PRODUCT_EDIT]: (user, options) => {
        if (!options?.product?.seller) {
            return false;
        }
        return options?.product.sellerId === user.b2bId;
    },
    [NETBOX]: ({ roles }) => Boolean(roles?.includes(ROLE_NETBOX_USER)),
    [LGD]: ({ roles }) => Boolean(roles?.includes(ROLE_MANAGER) && roles?.includes(ROLE_LABGROWN)),
    [MANAGER]: ({ roles }) => Boolean(roles?.includes(ROLE_MANAGER)),
    [INTERNAL]: ({ roles }) => Boolean(roles?.includes(ROLE_INTERNAL)),
    [SHARE_ACCESS]: ({ roles }) => Boolean(roles?.includes(ROLE_MANAGER) || roles?.includes(ROLE_RECUT_MANAGER)),
    [ADMIN]: ({ roles }) => Boolean(roles?.includes(ROLE_SUPER_ADMIN)),
    [VIEW_PRODUCT_STATUS]: (user, options) => (ACTION_GRANTERS[MANAGER](user) && ACTION_GRANTERS[PRODUCT_EDIT](user, { product: options?.product })) ||
        ACTION_GRANTERS[ADMIN](user),
    [RENDERER]: ({ roles }) => Boolean(roles?.includes(ROLE_RENDERER)),
    [RENDER_MANAGER]: (user) => ACTION_GRANTERS[RENDERER](user) && ACTION_GRANTERS[MANAGER](user),
    [GRADE_PRODUCT]: (user, options) => ACTION_GRANTERS[PRODUCT_EDIT](user, { product: options?.product }),
    [MANAGE_PROJECT]: ({ b2bId }, options) => Boolean(options?.project?.b2b?.id === b2bId),
};
export default class SecurityManager {
    user;
    setUser(user) {
        this.user = user;
    }
    isGranted(action, options = null) {
        if (!this.user?.roles) {
            return false;
        }
        return ACTION_GRANTERS[action]?.(this.user, options) ?? null;
    }
}
__decorate([
    observable.ref
], SecurityManager.prototype, "user", void 0);
export const securityManager = new SecurityManager();
