import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaSource from '../../entity/MediaSource';
import styles from './Image.less';
import Media from '../../entity/Media';
import {MEDIA_SIZE_LARGE} from '../../constants/sizeCodes';
import {MEDIA_SIZE_MAP} from '../../constants/sizeCodeToPx';
import FSetImage from '../FSetImage/FSetImage';
import Link, {NONE} from '../../../common/components/Link/Link';

export default class Image extends Component {
  static propTypes = {
    media: PropTypes.instanceOf(Media).isRequired,
    mediaSource: PropTypes.instanceOf(MediaSource).isRequired,
    mediaSubFile: PropTypes.instanceOf(Object),
    className: PropTypes.string,
    mediaSize: PropTypes.string,
    containerSize: PropTypes.string,
    maxScale: PropTypes.number,
    isSingleScale: PropTypes.bool,
    isGrayscale: PropTypes.bool,
    isSaturation: PropTypes.bool,
    href: PropTypes.string,
    hqMode: PropTypes.bool,
  };

  static defaultProps = {
    mediaSubFile: null,
    mediaSize: MEDIA_SIZE_LARGE,
    containerSize: null,
    className: '',
    isSingleScale: false,
    isGrayscale: false,
    isSaturation: false,
    href: '',
    maxScale: null,
    hqMode: false,
  };

  get containerSizeInPx() {
    const resultedSizeCode = this.props.containerSize || this.props.mediaSize;

    return MEDIA_SIZE_MAP[resultedSizeCode];
  }

  renderPolishImage() {
    const mediaSubFile = this.props.mediaSubFile;

    return <img className={styles.mediaSectionFit} src={mediaSubFile ? mediaSubFile.externalLink : ''} />;
  }

  renderImage() {
    const {
      media, mediaSource, mediaSize, isSingleScale, hqMode,
    } = this.props;

    const subFileMediaSize = MEDIA_SIZE_MAP[mediaSize] * (hqMode ? 2 : 1);
    const subFile = mediaSource.getMaxSubFileByMediaSize(subFileMediaSize);

    if (!subFile) {
      return null;
    }

    let width = subFile.resolutionX;
    let height = subFile.resolutionY;

    const containerScaleRatio = this.containerSizeInPx / Math.max(width, height);

    let singleScaleRatio = 1;
    if (isSingleScale) {
      if (mediaSource.scaleRatio && this.props.maxScale) {
        singleScaleRatio = mediaSource.relScale / this.props.maxScale;
      }
      const maxSide = Math.max(height, width);

      if (maxSide < subFileMediaSize) {
        singleScaleRatio *= subFileMediaSize / maxSide;
      }
    }

    width *= singleScaleRatio * containerScaleRatio;
    height *= singleScaleRatio * containerScaleRatio;
    const imageStyles = isSingleScale ? { width: `${width}px`, height: `${height}px` } : {};

    let image;
    if (media.isVideo) {
      image = <FSetImage style={imageStyles} mediaSubFile={subFile} alt={media.setupPreset.title} />;
    } else {
      image = <img draggable="false" style={imageStyles} src={subFile ? subFile.externalLink : ''} alt={media.setupPreset.title} />;
    }

    return this.props.href ? <Link href={this.props.href} appearance={NONE}>{image}</Link> : image;
  }

  render() {
    const {
      media, mediaSource, mediaSubFile, className, isGrayscale, isSaturation,
    } = this.props;

    if (mediaSubFile) {
      return <div className={`${styles.mediaSection} ${className}`} style={{ '--media-section-size': `${this.containerSizeInPx}px` }}>{this.renderPolishImage()}</div>;
    }

    return (
      <div
        className={`${styles.mediaSection} ${className} ${isGrayscale ? styles.mediaSectionGrayscale : ''} ${isSaturation ? styles.mediaSectionSaturation : ''}`}
        style={{ backgroundColor: mediaSource ? mediaSource.bgColorOverrided : media.bgColor, '--media-section-size': `${this.containerSizeInPx}px` }}
      >
        {this.renderImage()}
      </div>
    );
  }
}
