import { isArray } from '@cutwise/utils';
import Storage, { KEY_PINNED_ITEMS } from '@/common/services/Storage';
class PinnedItemsStorage {
    static get() {
        const value = Storage.getObject(KEY_PINNED_ITEMS, Storage.STORAGE_LOCAL);
        return isArray(value) ? value : [];
    }
    static save(state) {
        Storage.saveObject(KEY_PINNED_ITEMS, state, Storage.STORAGE_LOCAL);
    }
}
export default PinnedItemsStorage;
