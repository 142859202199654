var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, observable, reaction } from 'mobx';
import CollectionsAPI from '@/collection/api/CollectionsAPI';
import Collection from '@/collection/entity/Collection';
import { toAppContextProductType } from '@/collection/entity/CollectionType';
import { sortCollectionsByDate } from '@/collection/helpers/sortCollectionsByDate';
import useStore from '@/common/hooks/useStore';
import { userStore } from '@/user/stores/UserStore';
// DriveCollectionsStore handles request caching.
// The collection list is frequently used for adding items to a collection,
// and loading delays significantly interfere with this process.
// The caching logic is currently implemented in the simplest way possible,
// which leads to unnecessary backend requests for drive users
export class DriveCollectionsStore {
    collectionsAPI;
    collections = null;
    constructor(userStore, collectionsAPI = new CollectionsAPI()) {
        this.collectionsAPI = collectionsAPI;
        reaction(() => userStore.user, (user) => {
            if (user?.isAnonymous) {
                this.collections = null;
            }
        });
    }
    refresh = async () => {
        const collectionsRes = await this.collectionsAPI.fetchOwned();
        const content = collectionsRes.content;
        this.collections = sortCollectionsByDate(content.map((d) => new Collection(d, toAppContextProductType(d.type))));
    };
}
__decorate([
    observable
], DriveCollectionsStore.prototype, "collections", void 0);
__decorate([
    action
], DriveCollectionsStore.prototype, "refresh", void 0);
export const useDriveCollectionsStore = () => useStore(DriveCollectionsStore);
export const driveCollectionsStore = new DriveCollectionsStore(userStore);
export default DriveCollectionsStore;
