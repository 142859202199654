import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAppStore } from '@/common/stores/AppStore';
import './MediaPreview.less';
import Icon from '../../../common/components/Icon/Icon';
import Image from '../Image/Image';
import MediaPlaceholder from '../MediaPlaceholder/MediaPlaceholder';
import { getProductShapeIcon } from '../MediaPlaceholder/MediaPlaceholder.utils';
function MediaPreview(props) {
    const { media, mediaSource, className, index = 0, active = false, disabled = false, mediaSize, showType = false, isSingleScale = true, maxScale, isGrayscale = false, containerSize, product, isSaturation = false, size, onClick, style: customStyle, } = props;
    const usedSize = containerSize || mediaSize;
    const shouldRenderMedia = Boolean(media && mediaSource && !mediaSource.isEmpty);
    const { isHQModeEnabled } = useAppStore();
    if (shouldRenderMedia) {
        const style = {
            ...(customStyle || {}),
            backgroundColor: media.bgColor,
            order: index,
        };
        if (size) {
            style.height = `${size}px`;
            style.width = `${size}px`;
        }
        return (_jsxs("div", { className: `media-preview media-preview--${usedSize} ${active ? 'media-preview--active' : ''} ${disabled ? 'media-preview--disabled' : ''} ${className || ''}`, onClick: onClick, style: style, children: [media.isVideo && showType && (_jsx("div", { className: "media-preview__video-flag", children: _jsx(Icon, { name: "play" }) })), _jsx(Image, { containerSize: usedSize, hqMode: isHQModeEnabled, isGrayscale: isGrayscale, isSaturation: isSaturation, isSingleScale: isSingleScale, maxScale: maxScale, media: media, mediaSize: mediaSize, mediaSource: mediaSource })] }));
    }
    return (_jsx(MediaPlaceholder, { className: `media-preview media-preview--${usedSize}`, shape: getProductShapeIcon(product), size: size, style: { order: index } }));
}
export default observer(MediaPreview);
