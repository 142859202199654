import CutwiseAPIClient, { METHOD_DELETE, METHOD_POST, METHOD_PUT } from '@/common/network/CutwiseAPIClient';
export const PATH_COMMENT = 'api/comment';
var CommentContextType;
(function (CommentContextType) {
    CommentContextType["Collection"] = "collection";
})(CommentContextType || (CommentContextType = {}));
var CommentObjectType;
(function (CommentObjectType) {
    CommentObjectType["Product"] = "product";
})(CommentObjectType || (CommentObjectType = {}));
class CommentAPI {
    async createCollectionComment(collectionId, productId, text) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_POST).path(PATH_COMMENT).body({
            contextType: CommentContextType.Collection,
            contextId: collectionId,
            objectType: CommentObjectType.Product,
            objectId: productId,
            text,
        });
        const query = qb.getQuery();
        const res = await CutwiseAPIClient.sendQuery(query);
        return res.content;
    }
    async updateCollectionComment(collectionId, productId, text) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_PUT).path(PATH_COMMENT).body({
            contextType: CommentContextType.Collection,
            contextId: collectionId,
            objectType: CommentObjectType.Product,
            objectId: productId,
            text,
        });
        const query = qb.getQuery();
        const res = await CutwiseAPIClient.sendQuery(query);
        return res.content;
    }
    delete(commentId) {
        const qb = CutwiseAPIClient.createQueryBuilder().method(METHOD_DELETE).path(`${PATH_COMMENT}/${commentId}`);
        const query = qb.getQuery();
        return CutwiseAPIClient.sendQuery(query);
    }
}
export default CommentAPI;
