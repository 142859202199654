export const extractProductAnalyticDimensions = (product) => {
    if (!product) {
        return {};
    }
    return {
        id: product.id,
        b2bId: product.sellerId,
        b2bSid: product.b2bSid,
    };
};
