export default class DeadZoneMetric {
    h;
    l;
    m;
    map;
    n;
    val;
    constructor(model) {
        this.h = Math.round(model.h * 100);
        this.l = Math.round(model.l * 100);
        this.m = Math.round(model.m * 100);
        this.n = Math.round(model.n * 100);
        this.map = model.map;
        this.val = model.val.toFixed(2);
    }
}
