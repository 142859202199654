import { useCallback, useRef } from 'react';
import { logger, useUnmount } from '@cutwise/utils';
import { CLICK_SHARE } from '@/analytics/services/Analytics';
import { useAnalyticsStore } from '@/analytics/store/AnalyticsStore';
import { PreviewService } from '@/media/services/ProductPreviewService';
export const useNativeShare = () => {
    const analyticsStore = useAnalyticsStore();
    const abortControllerRef = useRef();
    useUnmount(() => abortControllerRef.current?.abort());
    return useCallback(async (shareConfig) => {
        analyticsStore.sendEventWithContext(CLICK_SHARE, shareConfig.analyticDimensions);
        try {
            const previewUrl = shareConfig.previewUrl;
            if (!previewUrl) {
                await navigator.share(shareConfig);
                return;
            }
            abortControllerRef.current = new AbortController();
            const previewFile = await PreviewService.fetchPreviewFile(previewUrl, abortControllerRef.current.signal);
            if (previewFile && navigator.canShare && navigator.canShare({ files: [previewFile] })) {
                await navigator.share({ ...shareConfig, files: [previewFile] });
                return;
            }
            await navigator.share(shareConfig);
        }
        catch (error) {
            const shareCancelled = error?.name === 'AbortError';
            if (shareCancelled) {
                return;
            }
            logger.error('Failed to share', error);
        }
    }, []);
};
