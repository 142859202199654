import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import Icon from '@/common/components/Icon/Icon';
import { DriveItemType } from '@/drive/drivePrototype/entities/DriveItem';
import { setPreviewUrlOptions } from '@/drive/utils/setPreviewUrlOptions';
import MediaPlaceholder from '@/media/components/MediaPlaceholder/MediaPlaceholder';
import MediaPreview from '@/media/components/MediaPreview/MediaPreview';
import ProjectPlaceholder from '@/media/components/ProjectPlaceholder/ProjectPlaceholder';
import { mediaSizeToPx } from '@/media/constants/sizeCodeToPx';
import styles from './DriveItemPreview.module.less';
function DriveItemPreview(props) {
    const { preview, mediaSize } = props;
    const size = mediaSizeToPx(mediaSize);
    if (preview.type === DriveItemType.CarbonProject) {
        return _jsx(ProjectPlaceholder, { width: size, height: size });
    }
    else if (preview.type === DriveItemType.Folder) {
        return _jsx(Icon, { name: "folder", className: styles.folderIcon, style: { fontSize: size } });
    }
    else if (preview.type === DriveItemType.Collection) {
        return _jsx(Icon, { name: "like", className: styles.icon, style: { fontSize: size } });
    }
    else if (preview.type === 'productEntity') {
        const media = preview.product?.defaultMedia;
        return (_jsx(MediaPreview, { media: media, mediaSize: mediaSize, mediaSource: media?.mainMediaSource, product: preview.product }));
    }
    else if (preview.type === DriveItemType.Url) {
        return _jsx(Icon, { name: "link", className: styles.urlIcon, style: { fontSize: size } });
    }
    else if (preview.preview) {
        return (_jsx("img", { className: styles.img, src: setPreviewUrlOptions(preview.preview, size), width: size, height: size, alt: "" }));
    }
    else {
        return _jsx(MediaPlaceholder, { shape: preview.productType ?? undefined, size: size });
    }
}
export default observer(DriveItemPreview);
