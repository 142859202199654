import { jsx as _jsx } from "react/jsx-runtime";
import cls from 'classnames';
import styles from './ButtonGroup.less';
export default function ButtonGroup(props) {
    const { className, withOutline = false, ariaLabel, block = false, children } = props;
    return (_jsx("div", { "aria-label": ariaLabel, className: cls(styles.btnGroup, {
            [styles.btnGroupWithOutline]: withOutline,
            [styles.btnGroupBlock]: block,
        }, className), role: "group", children: children }));
}
