import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, Fragment, memo, useEffect, useState } from 'react';
import cls from 'classnames';
import { autoUpdate, flip, FloatingFocusManager, FloatingPortal, offset, shift, useClick, useDismiss, useFloating, useHover, useInteractions, } from '@floating-ui/react';
import styles from './FancyPopup.module.less';
function FancyPopup(props) {
    const { isOpen, onChangeState, action, trigger, body, placement = 'top', strategy, className, usePortal, parentNode, } = props;
    const isControlled = typeof isOpen === 'boolean' && typeof onChangeState === 'function';
    const [isOpened, setIsOpened] = useState(isControlled ? isOpen : false);
    const { refs, floatingStyles, context } = useFloating({
        placement,
        strategy,
        open: isOpened,
        onOpenChange: isControlled ? onChangeState : setIsOpened,
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
    });
    const click = useClick(context, {
        enabled: action === 'click',
    });
    const hover = useHover(context, {
        enabled: action === 'hover',
        move: false,
    });
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([click, hover, dismiss]);
    function getChildren() {
        return typeof trigger.type === 'function' ? trigger.type(trigger.props) : trigger;
    }
    useEffect(() => {
        if (isControlled) {
            setIsOpened(isOpen);
        }
    }, [isControlled, isOpen]);
    const content = (_jsx(FloatingFocusManager, { context: context, modal: false, closeOnFocusOut: false, children: _jsx("div", { className: cls(styles.content, className), ref: refs.setFloating, style: floatingStyles, ...getFloatingProps(), children: body }) }));
    const child = getChildren();
    return (_jsxs(Fragment, { children: [cloneElement(child, {
                ref: refs.setReference,
                ...getReferenceProps({
                    onClick(e) {
                        // avoid propagation, because there might be situations, when a popup is placed inside div with "onClick",
                        // in this case, we shouldn't call it ("onClick" on parent div);
                        //
                        // it might lead to unexpected behavior - open popup and call "onClick" on parent div
                        e.stopPropagation();
                        child?.props?.onClick?.(e);
                    },
                }),
            }), isOpened && (usePortal ? _jsx(FloatingPortal, { root: parentNode, children: content }) : content)] }));
}
export default memo(FancyPopup);
