import { jsx as _jsx } from "react/jsx-runtime";
import cls from 'classnames';
import Link, { NONE } from '@/common/components/Link/Link';
import ProductPlaceholder from '@/media/components/ProductPlaceholder/ProductPlaceholder';
import './MediaPlaceholder.less';
export default function MediaPlaceholder(props) {
    const { href = '', size = null, shape, className, style: baseStyle, navigationTarget } = props;
    const style = { ...baseStyle };
    if (size) {
        style.height = `${size}px`;
        style.width = `${size}px`;
    }
    const icon = _jsx(ProductPlaceholder, { shape: shape, color: "outline" });
    return (_jsx("div", { className: cls('media-in-list-placeholder', className), style: style, children: _jsx("div", { className: "media-in-list-placeholder__content-wrapper", children: href ? (_jsx(Link, { href: href, appearance: NONE, target: navigationTarget, children: icon })) : (icon) }) }));
}
