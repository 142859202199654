import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { noop } from 'lodash';
import { isEmpty } from '@cutwise/utils';
import Modal from '@/common/components/Modal/Modal';
import { withStores } from '@/common/hoc/withStores';
import { useAppStore } from '@/common/stores/AppStore';
import { useDriveItemModalForm } from '@/drive/drivePrototype/components/DriveItemModal/useDriveItemModalForm';
import { isSmScreen } from '@/drive/drivePrototype/components/Forwarder/ForwardModal/ForwardModal.utils';
import ForwardModalHeader from '@/drive/drivePrototype/components/Forwarder/ForwardModal/ForwardModalHeader/ForwardModalHeader';
import ForwardModalStore from '@/drive/drivePrototype/components/Forwarder/ForwardModal/ForwardModalStore';
import { useForwardModalStore } from '@/drive/drivePrototype/components/Forwarder/ForwardModal/ForwardModalStore';
import { createEmptyForm } from '@/drive/drivePrototype/types/driveItemModalFormData';
import styles from './ForwardModal.module.less';
import ForwardModalBodyMd from './ForwardModalBodyMd/ForwardModalBodyMd';
import ForwardModalBodySm from './ForwardModalBodySm/ForwardModalBodySm';
function ForwardModalComponent(props) {
    const { config, usePortal, onClose, onDriveForwardSuccess, openDriveItemModal, openAddToCollectionModal, onQrClick } = props;
    const { isSubmitting, refresh, reset, submit, addToSpectrum } = useForwardModalStore();
    const { screenWidth } = useAppStore();
    const initialForm = useMemo(() => (config ? config.getForm() : createEmptyForm()), [config]);
    useEffect(() => {
        if (config) {
            refresh(config);
        }
        else {
            reset();
        }
    }, [config]);
    const { values, isValid, errors, validateForm, setFieldValue } = useDriveItemModalForm(noop, initialForm, config?.formDisabled());
    const handleDestinationSelect = useCallback(async (destination) => {
        if (!config || isSubmitting) {
            return;
        }
        const errors = await validateForm();
        if (!isEmpty(errors)) {
            return;
        }
        const { success } = await submit(config, values, destination);
        if (success) {
            onClose();
            onDriveForwardSuccess?.();
        }
    }, [config, isValid, values, submit, onDriveForwardSuccess]);
    const handleAddToSpectrum = useCallback(async () => {
        if (!config) {
            return;
        }
        const { success } = await addToSpectrum(config, values);
        if (success) {
            onClose();
        }
    }, [config, values, addToSpectrum, onClose]);
    // On mobile screens, we disable autofocus to prevent the keyboard from opening
    //  (otherwise, the forward actions are hidden behind it).
    const autoFocusField = !isSmScreen(screenWidth) ? 'title' : null;
    return (_jsx(Modal, { isShowed: !!config, onClose: onClose, fitWidthByContent: true, title: config && (_jsx(ForwardModalHeader, { autoFocusField: autoFocusField, config: config, values: values, setFieldValue: setFieldValue, errors: errors })), contentClassName: styles.modal, usePortal: usePortal, children: config &&
            (isSmScreen(screenWidth) ? (_jsx(ForwardModalBodySm, { config: config, onDestinationSelect: handleDestinationSelect, openDriveItemModal: (action) => openDriveItemModal(config, action), openAddToCollectionModal: () => openAddToCollectionModal(config), addToSpectrum: handleAddToSpectrum, onQrClick: onQrClick })) : (_jsx(ForwardModalBodyMd, { config: config, onDestinationSelect: handleDestinationSelect, openDriveItemModal: (action) => openDriveItemModal(config, action), openAddToCollectionModal: () => openAddToCollectionModal(config), addToSpectrum: handleAddToSpectrum, onQrClick: onQrClick }))) }));
}
export default withStores(({ userStore, driveCollectionsStore }) => ({
    forwardModalStore: new ForwardModalStore(userStore, driveCollectionsStore),
}), observer(ForwardModalComponent));
