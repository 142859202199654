import { MEDIA_SIZE_LARGE } from '@/media/constants/sizeCodes';
import { MEDIA_SIZE_MAP } from '@/media/constants/sizeCodeToPx';
import getFirstFrameUrl from '@/media/helpers/getFirstFrameUrl';
export class PreviewService {
    static getMediaPreviewUrl(media) {
        if (!media) {
            return null;
        }
        const mainMediaSource = media.mainMediaSource;
        if (!mainMediaSource) {
            return null;
        }
        const maxSubFile = mainMediaSource.getMaxSubFileByMediaSize(MEDIA_SIZE_MAP[MEDIA_SIZE_LARGE]);
        if (!maxSubFile) {
            return null;
        }
        if (media.isImage) {
            return maxSubFile.externalLink;
        }
        return getFirstFrameUrl(maxSubFile);
    }
    static async fetchPreviewFile(previewUrl, signal, fileName = 'preview.jpg') {
        try {
            const response = await fetch(previewUrl, { signal });
            const blob = await response.blob();
            return new File([blob], fileName, { type: blob.type });
        }
        catch (e) {
            console.warn(e);
            return null;
        }
    }
}
