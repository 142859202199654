// extracted by mini-css-extract-plugin
var _1 = "sVoGVw0Ga7_nn5X2g3Ug";
var _2 = "#76F366";
var _3 = "#EB8632";
var _4 = "#ECC834";
var _5 = "#21FC87";
var _6 = "#EA4A30";
var _7 = "#C7EA45";
var _8 = "#272B2B";
var _9 = "#BDBDBD";
var _a = "#7D7D7D";
var _b = "#6A0DAD";
var _c = "#3A65FF";
var _d = "#4A90E2";
var _e = "#ffffff";
var _f = "sIPGRqSrqs1TTCct0eww";
var _10 = "c3Pbu7dEabYBSToLyepx";
var _11 = "jvS3GI0DcEYKlCS9Yvkv";
var _12 = "KtJbRAHPd0g80VgVhYj1";
var _13 = "OxLzayHLPalobg3mJ2MH";
var _14 = "zj_uJBBzKo3TeW98JWnj";
var _15 = "zeewqfqBfZFn9LBTTYox";
var _16 = "po38jDtlwLaRdjq7zvYJ";
var _17 = "CF9JTX3mTE3P53f2qGAx";
var _18 = "vmzQQ7oo5bU75St62hlW";
var _19 = "1200px";
var _1a = "992px";
var _1b = "1366px";
var _1c = "768px";
var _1d = "1475px";
var _1e = "576px";
var _1f = "1600px";
var _20 = "320px";
var _21 = "2500px";
var _22 = "d2DgfEuJXKIRxBNvbRvP";
var _23 = "eczHyU0ieFFXxaP_5DxK";
export { _1 as "base-link", _2 as "colorGradeEX", _3 as "colorGradeFR", _4 as "colorGradeGD", _5 as "colorGradeOU", _6 as "colorGradePR", _7 as "colorGradeVG", _8 as "colorNeutral", _9 as "colorNeutralLighest", _a as "colorNeutralLight", _b as "colorNonOwnerSweetLine", _c as "colorOwnerSweetLine", _d as "colorRegularLinks", _e as "colorWhite", _f as "iconMore", _10 as "menuItem", _11 as "menuItemIcon", _12 as "moreContainer", _13 as "pinIcon", _14 as "pinNavItem", _15 as "pinPopup", _16 as "product-block-grid", _17 as "product-block-subgrid", _18 as "reset-button", _19 as "screenlg", _1a as "screenmd", _1b as "screenml", _1c as "screensm", _1d as "screenxlg", _1e as "screenxs", _1f as "screenxxlg", _20 as "screenxxs", _21 as "screenxxxlg", _22 as "single-page-responsive-font-size", _23 as "textContainer" }
export default { "base-link": _1, "colorGradeEX": _2, "colorGradeFR": _3, "colorGradeGD": _4, "colorGradeOU": _5, "colorGradePR": _6, "colorGradeVG": _7, "colorNeutral": _8, "colorNeutralLighest": _9, "colorNeutralLight": _a, "colorNonOwnerSweetLine": _b, "colorOwnerSweetLine": _c, "colorRegularLinks": _d, "colorWhite": _e, "iconMore": _f, "menuItem": _10, "menuItemIcon": _11, "moreContainer": _12, "pinIcon": _13, "pinNavItem": _14, "pinPopup": _15, "product-block-grid": _16, "product-block-subgrid": _17, "reset-button": _18, "screenlg": _19, "screenmd": _1a, "screenml": _1b, "screensm": _1c, "screenxlg": _1d, "screenxs": _1e, "screenxxlg": _1f, "screenxxs": _20, "screenxxxlg": _21, "single-page-responsive-font-size": _22, "textContainer": _23 }
