import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Provider } from 'mobx-react';
import globalStore from '@/store';
export function withStores(stores, Component) {
    return function withStoreComponent(props) {
        const store = useMemo(() => stores(globalStore), []);
        return (_jsx(Provider, { ...store, children: _jsx(Component, { ...props }) }));
    };
}
