import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import EmptyPlaceholder from '@/common/components/EmptyPlaceholder/EmptyPlaceholder';
import styles from './EmptyTreePlaceholder.module.less';
function EmptyTreePlaceholder(props) {
    const { isTreeFilteredOut } = props;
    if (isTreeFilteredOut) {
        return (_jsx(EmptyPlaceholder, { className: styles.emptyTreePlaceholder, children: "No items meet the current filter criteria" }));
    }
    return (_jsxs(EmptyPlaceholder, { className: styles.emptyTreePlaceholder, children: ["You don\u2019t have any items", _jsx("br", {}), "Add one to proceed"] }));
}
export default observer(EmptyTreePlaceholder);
