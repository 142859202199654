import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { closestCenter, defaultDropAnimation, DndContext, DragOverlay, KeyboardSensor, MeasuringStrategy, PointerSensor, useSensor, useSensors, } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { sortableTreeKeyboardCoordinates } from '@/drive/components/SortableTree/keyboardCoordinates';
import { SortableTreeItem } from '@/drive/components/SortableTree/TreeItemView/SortableTreeItem';
import { buildTree, getChildCount, getProjection, removeChildrenOf } from './SortableTree.utils';
const measuring = {
    droppable: {
        strategy: MeasuringStrategy.Always,
    },
};
const dropAnimationConfig = {
    keyframes({ transform }) {
        return [
            { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
            {
                opacity: 0,
                transform: CSS.Transform.toString({
                    ...transform.final,
                    x: transform.final.x + 5,
                    y: transform.final.y + 5,
                }),
            },
        ];
    },
    easing: 'ease-out',
    sideEffects({ active }) {
        active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: defaultDropAnimation.duration,
            easing: defaultDropAnimation.easing,
        });
    },
};
export function SortableTree(props) {
    const { items, selectedId, onSelect, indicator = false, indentationWidth = 15, itemClassName, itemContentClassName, onPositionChange, onCollapse, onRemove, flattenTree, renderItem, getProjection: getProjectionCustom, } = props;
    const [activeId, setActiveId] = useState(null);
    const [overId, setOverId] = useState(null);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const flattenedItems = useMemo(() => {
        const collapsedItems = flattenTree(items).reduce((acc, { collapsed, original, id }) => (collapsed && original.children?.length ? [...acc, id] : acc), []);
        return removeChildrenOf(flattenTree(items), activeId ? [activeId, ...collapsedItems] : collapsedItems);
    }, [activeId, items]);
    const projected = activeId && overId
        ? typeof getProjectionCustom === 'function'
            ? getProjectionCustom(flattenedItems, activeId, overId, offsetLeft, indentationWidth)
            : getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth)
        : null;
    const sensorContext = useRef({ items: flattenedItems, offset: offsetLeft });
    const [coordinateGetter] = useState(() => sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth));
    const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, { coordinateGetter }));
    const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
    const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;
    useEffect(() => {
        sensorContext.current = {
            items: flattenedItems,
            offset: offsetLeft,
        };
    }, [flattenedItems, offsetLeft]);
    function handleDragStart({ active: { id: activeId } }) {
        setActiveId(activeId);
        setOverId(activeId);
        document.body.style.setProperty('cursor', 'grabbing');
    }
    function handleDragMove({ delta }) {
        setOffsetLeft(delta.x);
    }
    function handleDragOver({ over }) {
        setOverId(over?.id ?? null);
    }
    function handleDragEnd({ active, over }) {
        resetState();
        if (projected && over) {
            const { depth, parentId } = projected;
            const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)));
            const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
            const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
            const activeTreeItem = clonedItems[activeIndex];
            clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };
            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
            const newItems = buildTree(sortedItems);
            onPositionChange?.(newItems, clonedItems[activeIndex]);
        }
    }
    function resetState() {
        setOverId(null);
        setActiveId(null);
        setOffsetLeft(0);
        document.body.style.setProperty('cursor', '');
    }
    return (_jsx(DndContext, { sensors: sensors, collisionDetection: closestCenter, measuring: measuring, onDragStart: handleDragStart, onDragMove: handleDragMove, onDragOver: handleDragOver, onDragEnd: handleDragEnd, onDragCancel: resetState, children: _jsxs(SortableContext, { items: sortedIds, strategy: verticalListSortingStrategy, children: [flattenedItems.map(({ id, editable, hasGroupChildren, collapsed, original, depth }) => (_jsx(SortableTreeItem, { className: itemClassName, item: original, contentClassName: itemContentClassName, id: id, depth: id === activeId && projected ? projected.depth : depth, indentationWidth: indentationWidth, renderItem: renderItem, indicator: indicator, selected: id === selectedId, collapsed: Boolean(collapsed && hasGroupChildren), onSelect: onSelect ? () => onSelect(original) : undefined, onCollapse: onCollapse && hasGroupChildren ? () => onCollapse(original) : undefined, onRemove: onRemove && editable ? () => onRemove(id) : undefined }, id))), createPortal(_jsx(DragOverlay, { dropAnimation: dropAnimationConfig, modifiers: indicator ? [adjustTranslate] : undefined, children: activeId && activeItem ? (_jsx(SortableTreeItem, { id: activeId, contentClassName: itemContentClassName, depth: activeItem.depth, selected: true, clone: true, renderItem: renderItem, childCount: getChildCount(items, activeId) + 1, item: activeItem.original, indentationWidth: indentationWidth })) : null }), document.body)] }) }));
}
const adjustTranslate = ({ transform }) => {
    return {
        ...transform,
        y: transform.y - 25,
    };
};
