import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './FSetImage.less';
import getFirstFrameUrl from '../../helpers/getFirstFrameUrl';

export default class FSetImage extends PureComponent {
  static propTypes = {
    mediaSubFile: PropTypes.shape({
      id: PropTypes.number,
      externalLink: PropTypes.string.isRequired,
      resolutionX: PropTypes.number,
      resolutionY: PropTypes.number,
      firstFrame: PropTypes.number,
    }).isRequired,
  };

  render() {
    const { mediaSubFile, ...rest } = this.props;

    return <img draggable="false" src={getFirstFrameUrl(mediaSubFile)} {...rest} />;
  }
}
