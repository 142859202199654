import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAppStore } from '@/common/stores/AppStore';
import TreeItemTitle from '@/drive/components/TreeItemModal/TreeItemTitle/TreeItemTitle';
import DriveItemModalForm from '@/drive/drivePrototype/components/DriveItemModal/DriveItemModalForm/DriveItemModalForm';
import DriveItemPreview from '@/drive/drivePrototype/components/DriveItemPreview/DriveItemPreview';
import { isSmScreen } from '@/drive/drivePrototype/components/Forwarder/ForwardModal/ForwardModal.utils';
import { MediaSize } from '@/media/constants/sizeCodes';
import styles from './ForwardModalHeader.module.less';
function ForwardModalHeader(props) {
    const { config, values, setFieldValue, errors, autoFocusField } = props;
    const { screenWidth } = useAppStore();
    return (_jsx(TreeItemTitle, { filterShown: false, title: "Forward", children: _jsxs("div", { className: styles.heading, children: [_jsx(DriveItemPreview, { preview: config.getPreview(), mediaSize: isSmScreen(screenWidth) ? MediaSize.XSmall : MediaSize.Small }), _jsx(DriveItemModalForm, { values: values, setFieldValue: setFieldValue, errors: errors, disabled: config.formDisabled(), autoFocusField: autoFocusField, hideLabels: true, notePlaceholder: "Type your forward note here" })] }) }));
}
export default observer(ForwardModalHeader);
