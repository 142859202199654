import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { observer } from 'mobx-react';
import Icon from '@/common/components/Icon/Icon';
import FancyInput from '@/common/components/inputs/FancyInput/FancyInput';
import styles from './TreeItemTitle.module.less';
function TreeItemTitle(props) {
    const { title, filterShown, filter, placeholder, children, onFilterChange, onBack } = props;
    return (_jsxs(Fragment, { children: [_jsxs("div", { className: styles.titleContainer, children: [onBack && _jsx(Icon, { name: "arrow-mid-left", className: styles.back, onClick: onBack }), _jsx("span", { className: styles.title, children: title })] }), children, filterShown && (_jsx("div", { className: styles.inputContainer, children: _jsx(FancyInput, { value: filter, onChange: onFilterChange, contentClassName: styles.filterInput, placeholder: placeholder, startIcon: "search" }) }))] }));
}
export default observer(TreeItemTitle);
