import { isEnumMember } from '@cutwise/utils';
export var DriveSection;
(function (DriveSection) {
    DriveSection["Public"] = "public";
    DriveSection["PublicDiamonds"] = "public-diamonds";
    DriveSection["PublicColorless"] = "public-colorless";
    DriveSection["PublicFancy"] = "public-fancy";
    DriveSection["PublicRough"] = "public-rough";
    DriveSection["PublicLgd"] = "public-ldg";
    DriveSection["PublicLgdColorless"] = "public--lgd-colorless";
    DriveSection["PublicLgdFancy"] = "public-lgd-fancy";
    DriveSection["PublicLgdRough"] = "public-lgd-rough";
    DriveSection["PublicJewelry"] = "public-jewelry";
    DriveSection["DemoCollections"] = "demo";
    DriveSection["PrivateRoot"] = "private";
    DriveSection["PrivateDiamonds"] = "private-diamonds";
    DriveSection["PrivateColorless"] = "private-colorless";
    DriveSection["PrivateFancy"] = "private-fancy";
    DriveSection["PrivateRough"] = "private-rough";
    DriveSection["PrivateLgd"] = "private-lgd";
    DriveSection["PrivateLgdColorless"] = "private-lgd-colorless";
    DriveSection["PrivateLgdFancy"] = "private-lgd-fancy";
    DriveSection["PrivateLgdRough"] = "private-lgd-rough";
    DriveSection["PrivateJewelry"] = "private-jewelry";
    DriveSection["PrivateCollections"] = "collections";
    DriveSection["PrivateProjects"] = "projects";
    DriveSection["MyProjects"] = "my-projects";
    DriveSection["SharedProjects"] = "shared-projects";
    DriveSection["Multistage"] = "multistage";
    DriveSection["Profile"] = "profile";
    DriveSection["Billing"] = "billing";
    DriveSection["Services"] = "services";
    DriveSection["Tasks"] = "tasks";
    DriveSection["PinnedItems"] = "pinned";
    DriveSection["MyDrive"] = "root";
    DriveSection["Spectrum"] = "spectrum";
})(DriveSection || (DriveSection = {}));
export function parseDriveParams({ section, selectedId } = {}) {
    const str = section?.toLowerCase();
    if (str === DriveSection.PinnedItems) {
        return { section: DriveSection.PinnedItems, selectedId: null };
    }
    else if (str === DriveSection.MyDrive) {
        return { section: DriveSection.MyDrive, selectedId: extractInt(selectedId) };
    }
    else if (str === DriveSection.PrivateCollections) {
        return { section: DriveSection.PrivateCollections, selectedId: extractInt(selectedId) };
    }
    else if (isEnumMember(section, DriveSection)) {
        return { section: section, selectedId: null };
    }
    return { section: DriveSection.MyDrive, selectedId: null };
}
function extractInt(str) {
    if (!str) {
        return null;
    }
    const parsed = parseFloat(str);
    return isFinite(parsed) ? parsed : null;
}
