import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useConfigurationStore } from '@/common/stores/ConfigurationStore';
import { RATIO_THICK } from '../../constants/ratio';
import MetricBar from '../MetricBar/MetricBar';
function DeadZoneMetricBar(props) {
    const { scaleBaseUri } = useConfigurationStore();
    const { metric, ratio = RATIO_THICK, children } = props;
    function renderMetricBar() {
        const params = new URLSearchParams({
            ratio: `${ratio}`,
        });
        if (metric) {
            const metrics = {
                n: metric.n / 100,
                l: metric.l / 100,
                m: metric.m / 100,
                h: metric.h / 100,
            };
            Object.keys(metrics).forEach((key) => {
                if (metrics[key]) {
                    params.append(key, metrics[key]);
                }
            });
        }
        return _jsx(MetricBar, { ratio: ratio, svg: `${scaleBaseUri}/deadzones/deadzones?${params.toString()}` });
    }
    return children ? children(renderMetricBar(), metric ? metric.val : null) : renderMetricBar();
}
export default observer(DeadZoneMetricBar);
