import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import styles from './SpreadV2FullLabel.module.less';
function SpreadV2FullLabel() {
    const onClickHandle = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open('/pr/#/22', '_blank');
    }, []);
    return (_jsxs("span", { children: [_jsx("span", { children: "Spread " }), _jsx("span", { onClick: onClickHandle, className: styles.link, children: "v2" })] }));
}
export default memo(SpreadV2FullLabel);
