var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from 'mobx';
import { Subject } from 'rxjs';
import CollectionsAPI from '@/collection/api/CollectionsAPI';
import useStore from '@/common/hooks/useStore';
import Notifier from '@/common/services/Notifier';
import PinnedItemsStorage from '@/drive/drivePrototype/services/PinnedItemsStorage';
class PinnedItemsStore {
    collectionsAPI;
    pinnedItems = PinnedItemsStorage.get();
    navbarDriveActionSuccessSubject = new Subject();
    navbarCollectionActionSuccessSubject = new Subject();
    navbarDriveActionSuccess$ = this.navbarDriveActionSuccessSubject.asObservable();
    navbarCollectionActionSuccess$ = this.navbarCollectionActionSuccessSubject.asObservable();
    get itemsIds() {
        return new Set(this.pinnedItems.map((item) => item.id));
    }
    constructor(collectionsAPI = new CollectionsAPI()) {
        this.collectionsAPI = collectionsAPI;
    }
    isPinned = (pinnedItem) => {
        return this.itemsIds.has(pinnedItem.id);
    };
    pin = (item) => {
        this.pinAll([item]);
    };
    pinAll = (items) => {
        const newItems = items.filter((item) => !this.itemsIds.has(item.id));
        this.pinnedItems = [...this.pinnedItems, ...newItems];
        PinnedItemsStorage.save(this.pinnedItems);
    };
    unpin = (id) => {
        this.pinnedItems = this.pinnedItems.filter((pinnedItem) => pinnedItem.id !== id);
        PinnedItemsStorage.save(this.pinnedItems);
    };
    switchPin = (item) => {
        if (this.isPinned(item)) {
            this.unpin(item.id);
        }
        else {
            this.pin(item);
        }
    };
    unpinAll = () => {
        this.pinnedItems = [];
        PinnedItemsStorage.save(this.pinnedItems);
    };
    onPinDriveActionSuccessInNavbar = () => {
        this.navbarDriveActionSuccessSubject.next(null);
    };
    saveAsCollection = async (form, productType, ids) => {
        const res = await this.collectionsAPI.create(productType, form.title, form.description);
        await this.collectionsAPI.addToCollection(res.content.id, ids);
        this.navbarCollectionActionSuccessSubject.next(null);
        Notifier.success('Saved');
    };
}
__decorate([
    observable
], PinnedItemsStore.prototype, "pinnedItems", void 0);
__decorate([
    computed
], PinnedItemsStore.prototype, "itemsIds", null);
__decorate([
    action
], PinnedItemsStore.prototype, "pin", void 0);
__decorate([
    action
], PinnedItemsStore.prototype, "pinAll", void 0);
__decorate([
    action
], PinnedItemsStore.prototype, "unpin", void 0);
__decorate([
    action
], PinnedItemsStore.prototype, "unpinAll", void 0);
export const usePinnedItemsStore = () => useStore(PinnedItemsStore);
export const pinnedItemsStore = new PinnedItemsStore();
export default PinnedItemsStore;
