export function toTreeItem(item) {
    return {
        id: item.id,
        title: item.title,
        type: 'appSection',
        children: item.children?.map(toTreeItem) ?? [],
        collapsed: item.collapsed ?? true,
        icon: item.icon,
        url: item.url,
        iconClassName: item.iconClassName,
    };
}
