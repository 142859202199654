import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Auth from '@/user/services/auth/Auth';
import Button from '../../components/Button/Button';
import Layout from '../../components/Layout/Layout';
import Link from '../../components/Link/Link';
import { IMG_COMMON, IMG_COMPARISON, IMG_PRIVACY } from '../../state/AppError';
import commonImg from './common.svg';
import comparisonImg from './comparison.svg';
import styles from './ErrorPage.module.less';
import privacyImg from './privacy.svg';
const IMAGES = {
    [IMG_COMMON]: commonImg,
    [IMG_COMPARISON]: comparisonImg,
    [IMG_PRIVACY]: privacyImg,
};
import { memo } from 'react';
function ErrorPage(props) {
    const { error } = props;
    return (_jsx(Layout, { children: _jsx("div", { className: styles.pageErrorWrapper, children: _jsxs("div", { className: styles.pageError, children: [error.code === 404 && (_jsx("div", { className: styles.pageErrorCode, "aria-label": "Error Code", children: "404" })), _jsx("div", { className: styles.pageErrorTitle, "aria-label": "Error Title", children: _jsx("h2", { children: error.title }) }), _jsx("div", { className: styles.pageErrorImage, "aria-label": "Error Image", children: _jsx("img", { alt: error.imgName, src: IMAGES[error.imgName] }) }), _jsxs("div", { className: styles.pageErrorDescription, "aria-label": "Error Description", children: [_jsx("div", { children: error.description }), _jsxs("div", { className: styles.pageErrorDescriptionLinks, "aria-label": "Error Links", children: [error.hasLinks && (_jsx("div", { className: styles.pageErrorDescriptionHasLinks, children: "Let's get you back on track with helpful link(s):" })), error.hasCatalogLink && (_jsxs("div", { children: ["Go to the ", _jsx(Link, { to: "/catalog", children: "Catalog page" })] })), error.hasCollectionLink && (_jsx("div", { children: _jsx(Link, { to: "/collections", children: "View Demo" }) })), error.hasLoginLink && (_jsx("div", { children: _jsx(Button, { onClick: Auth.redirectToLoginPage, children: "Log in" }) }))] })] })] }) }) }));
}
export default memo(ErrorPage);
