var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from 'mobx';
import { v4 } from 'uuid';
import CollectionsAPI from '@/collection/api/CollectionsAPI';
import Collection from '@/collection/entity/Collection';
import useStore from '@/common/hooks/useStore';
class AddToCollectionModalListStore {
    driveCollectionsStore;
    collectionsAPI;
    filter = '';
    productType = null;
    productId = null;
    get allCollections() {
        if (!this.driveCollectionsStore.collections || !this.productType) {
            return null;
        }
        return this.driveCollectionsStore.collections.filter((c) => c.type === Collection.getTypeByProductType(this.productType));
    }
    get disabledCollectionIds() {
        if (!this.allCollections || !this.productId) {
            return new Set();
        }
        return new Set(this.allCollections.filter((c) => c.productIds.includes(this.productId)).map((c) => c.id));
    }
    get filteredCollections() {
        if (!this.allCollections) {
            return null;
        }
        const filterText = this.filter.toLowerCase().trim();
        if (filterText.length === 0) {
            return this.allCollections;
        }
        return this.allCollections.filter((c) => c.title.toLowerCase().includes(filterText));
    }
    selectedId = null;
    isPendingCreation = false;
    newCollectionTitle = '';
    newCollectionId = null;
    constructor(driveCollectionsStore, collectionsAPI = new CollectionsAPI()) {
        this.driveCollectionsStore = driveCollectionsStore;
        this.collectionsAPI = collectionsAPI;
    }
    refresh = async (productId, productType) => {
        this.productType = productType;
        this.productId = productId;
        return this.driveCollectionsStore.refresh();
    };
    reset = () => {
        this.isPendingCreation = false;
        this.productType = null;
        this.productId = null;
        this.newCollectionTitle = '';
        this.newCollectionId = null;
        this.selectedId = null;
        this.filter = '';
    };
    setSelectedId = (id) => {
        this.selectedId = id;
    };
    changeFilter = (filter) => {
        this.filter = filter;
    };
    setNewCollectionTitle = (value) => {
        if (!this.isPendingCreation) {
            this.newCollectionTitle = value;
        }
    };
    startCreation = async () => {
        if (this.isPendingCreation) {
            return;
        }
        if (this.newCollectionTitle) {
            await this.finishCreation();
        }
        if (!this.allCollections) {
            // collections are null because user closed modal
            return;
        }
        this.newCollectionId = v4();
        this.newCollectionTitle = 'New Collection';
        this.selectedId = this.newCollectionId;
    };
    finishCreation = async () => {
        this.isPendingCreation = true;
        try {
            await this.createCollection();
            this.newCollectionTitle = '';
            this.newCollectionId = null;
        }
        finally {
            this.isPendingCreation = false;
        }
    };
    createCollection = async () => {
        const res = await this.collectionsAPI.create(this.productType, this.newCollectionTitle);
        if (!this.allCollections || !this.productType) {
            return;
        }
        this.allCollections?.unshift(new Collection(res.data, this.productType));
        if (this.selectedId === this.newCollectionId) {
            this.selectedId = res.data.id;
        }
    };
}
__decorate([
    observable
], AddToCollectionModalListStore.prototype, "filter", void 0);
__decorate([
    observable
], AddToCollectionModalListStore.prototype, "productType", void 0);
__decorate([
    observable
], AddToCollectionModalListStore.prototype, "productId", void 0);
__decorate([
    computed
], AddToCollectionModalListStore.prototype, "allCollections", null);
__decorate([
    computed
], AddToCollectionModalListStore.prototype, "disabledCollectionIds", null);
__decorate([
    computed
], AddToCollectionModalListStore.prototype, "filteredCollections", null);
__decorate([
    observable
], AddToCollectionModalListStore.prototype, "selectedId", void 0);
__decorate([
    observable
], AddToCollectionModalListStore.prototype, "isPendingCreation", void 0);
__decorate([
    observable
], AddToCollectionModalListStore.prototype, "newCollectionTitle", void 0);
__decorate([
    action
], AddToCollectionModalListStore.prototype, "refresh", void 0);
__decorate([
    action
], AddToCollectionModalListStore.prototype, "reset", void 0);
__decorate([
    action
], AddToCollectionModalListStore.prototype, "setSelectedId", void 0);
__decorate([
    action
], AddToCollectionModalListStore.prototype, "changeFilter", void 0);
__decorate([
    action
], AddToCollectionModalListStore.prototype, "setNewCollectionTitle", void 0);
__decorate([
    action
], AddToCollectionModalListStore.prototype, "startCreation", void 0);
__decorate([
    action
], AddToCollectionModalListStore.prototype, "finishCreation", void 0);
export const useAddToCollectionModalListStore = () => useStore(AddToCollectionModalListStore);
export default AddToCollectionModalListStore;
