import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import styles from './TreeItemContainer.module.less';
function TreeItemContainer(props) {
    const { isSelected, className, children, ...rest } = props;
    return (_jsx("div", { className: cls(styles.treeItemContainer, { [styles.selected]: isSelected }, className), ...rest, children: children }));
}
export default observer(TreeItemContainer);
