import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import Button from '@/common/components/Button/Button';
import Icon from '@/common/components/Icon/Icon';
import ButtonGroup from '@/common/components/inputs/ButtonGroup/ButtonGroup';
import { ForwardModalTab } from '../ForwardModalBody.types';
import styles from './ForwardModalTabs.module.less';
function ForwardModalTabs(props) {
    const { activeTab, onTabChange } = props;
    return (_jsxs("div", { className: styles.tabsContainer, children: [_jsx("div", { className: styles.title, children: "Destinations:" }), _jsxs(ButtonGroup, { className: styles.tabs, children: [_jsx(Button, { className: styles.tab, active: activeTab === ForwardModalTab.DestinationsList, onClick: () => onTabChange(ForwardModalTab.DestinationsList), withIcon: true, children: _jsx(Icon, { name: "clock-back", className: styles.clockIcon }) }), _jsx(Button, { className: styles.tab, active: activeTab === ForwardModalTab.Actions, onClick: () => onTabChange(ForwardModalTab.Actions), withIcon: true, children: _jsx(Icon, { name: "more-circles", className: styles.moreIcon }) })] })] }));
}
export default observer(ForwardModalTabs);
