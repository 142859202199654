export function getAncestors(items, id) {
    const { parents } = getAncestorsInner(items, id, { parents: [], isFound: false });
    return parents;
}
function getAncestorsInner(items, id, { parents, }) {
    for (const item of items) {
        if (item.id === id) {
            return { parents, isFound: true };
        }
        if (item.children) {
            const res = getAncestorsInner(item.children, id, { parents: [...parents, item], isFound: false });
            if (res && res.isFound) {
                return { parents: res.parents, isFound: true };
            }
        }
    }
    return { parents: [], isFound: false };
}
export function setProperty(items, id, property, setter) {
    for (const item of items) {
        if (item.id === id) {
            item[property] = setter(item[property]);
            continue;
        }
        if (item.children?.length) {
            item.children = setProperty(item.children, id, property, setter);
        }
    }
    return [...items];
}
export function updateItem(items, updated) {
    const res = [];
    for (const item of items) {
        if (item.id === updated.id) {
            res.push(updated);
        }
        else {
            if (item.children?.length) {
                item.children = updateItem(item.children, updated);
            }
            res.push(item);
        }
    }
    return res;
}
export function removeItem(items, id) {
    const newItems = [];
    for (const item of items) {
        if (item.id === id) {
            continue;
        }
        if (item.children) {
            item.children = removeItem(item.children, id);
        }
        newItems.push(item);
    }
    return newItems;
}
export function findItemDeep(items, id) {
    for (const item of items) {
        if (item.id === id) {
            return item;
        }
        if (item.children) {
            const child = findItemDeep(item.children, id);
            if (child) {
                return child;
            }
        }
    }
    return undefined;
}
