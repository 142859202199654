import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import SpreadFormatter from '@/common/helpers/SpreadFormatter';
import Diamond from '@/product/entity/Diamond';
export const FIELD_SPREAD_PC = {
    label: 'Spread v2',
    fullLabel: 'Spread v2, %',
    key: 'spreadPc',
    type: FieldType.Scalar,
    icon: 'metrics-spread',
    getter(p) {
        if (p instanceof Diamond && p.spreadMetric) {
            return SpreadFormatter.format(p.spreadMetric.pc, 1, '%');
        }
        return null;
    },
    getterPlain(p) {
        if (p instanceof Diamond && p.spreadMetric) {
            return p.spreadMetric.pc;
        }
        return null;
    },
    decorator: emptyDecorator,
    iteratee(p) {
        if (p instanceof Diamond && p.spreadMetric) {
            return p.spreadMetric.pc;
        }
        return null;
    },
    contexts: ALL_CONTEXTS,
};
