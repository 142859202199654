import { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { createEmptyForm } from '@/drive/drivePrototype/types/driveItemModalFormData';
const requiredValidationText = 'Required';
export function useDriveItemModalForm(onSubmit, initialForm = null, disabled = false, urlEnabled = false) {
    const formik = useFormik({
        validateOnMount: false,
        validateOnChange: false,
        initialValues: initialForm ?? createEmptyForm(),
        validate: (values) => {
            if (disabled) {
                return {};
            }
            const errors = {};
            if (!urlEnabled && values.title.trim().length === 0) {
                errors.title = requiredValidationText;
            }
            if (urlEnabled && values.url.trim().length === 0) {
                errors.url = requiredValidationText;
            }
            return errors;
        },
        onSubmit: onSubmit,
    });
    useEffect(() => {
        formik.setValues(initialForm ?? createEmptyForm());
        formik.setErrors({});
    }, [initialForm]);
    const setFieldValue = useCallback((name, val) => {
        formik.setFieldError(name, undefined);
        formik.setFieldValue(name, val);
    }, [formik.setFieldError, formik.setFieldValue]);
    return { ...formik, setFieldValue };
}
