import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import { useMount } from '@cutwise/utils';
import FancyInput from '@/common/components/inputs/FancyInput/FancyInput';
import styles from './TreeItemInput.module.less';
function TreeItemInput(props) {
    const { value, onChange, isPendingCreation, isSelected, save } = props;
    const inputRef = useRef(null);
    useMount(() => {
        inputRef.current?.focus();
        inputRef.current?.select();
    });
    const handleEnter = useCallback((e) => {
        if (e.key === 'Enter' && !isPendingCreation) {
            save();
        }
    }, [isPendingCreation, save]);
    return (_jsx(FancyInput, { value: value, contentClassName: cls(styles.treeItemInput, { [styles.selected]: isSelected }), onChange: onChange, onKeyDown: handleEnter, ref: inputRef }));
}
export default observer(TreeItemInput);
