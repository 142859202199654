import { ProductType } from '@/product/constants/productTypes';
export var CollectionType;
(function (CollectionType) {
    CollectionType[CollectionType["Diamond"] = 1] = "Diamond";
    CollectionType[CollectionType["Jewelry"] = 2] = "Jewelry";
    CollectionType[CollectionType["Rough"] = 3] = "Rough";
})(CollectionType || (CollectionType = {}));
export function toBaseProductType(collectionType) {
    if (collectionType === CollectionType.Jewelry) {
        return ProductType.Jewelry;
    }
    if (collectionType === CollectionType.Rough) {
        return ProductType.Rough;
    }
    return ProductType.Diamond;
}
export function toAppContextProductType(collectionType) {
    if (collectionType === CollectionType.Jewelry) {
        return ProductType.Jewelry;
    }
    if (collectionType === CollectionType.Rough) {
        return ProductType.Rough;
    }
    return ProductType.DiamondColorless;
}
