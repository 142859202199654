var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from 'mobx';
import CollectionsAPI from '@/collection/api/CollectionsAPI';
import CommentAPI from '@/collection/api/CommentAPI';
import useStore from '@/common/hooks/useStore';
import { DriveItemType } from '@/drive/drivePrototype/entities/DriveItem';
import ForwardDestinationStorage from '@/drive/drivePrototype/services/ForwardDestinationStorage';
import { ForwardNotificationService } from '@/drive/drivePrototype/services/ForwardNotificationService';
class AddToCollectionModalStore {
    collectionsAPI;
    commentsAPI;
    isSubmitting = false;
    constructor(collectionsAPI = new CollectionsAPI(), commentsAPI = new CommentAPI()) {
        this.collectionsAPI = collectionsAPI;
        this.commentsAPI = commentsAPI;
    }
    submit = async (config, form, parentId) => {
        this.isSubmitting = true;
        ForwardDestinationStorage.add({ id: parentId, type: DriveItemType.Collection });
        try {
            await Promise.all([
                this.collectionsAPI.addToCollection(parentId, config.getCollectionReqData().ids),
                this.createCommentIfChangedByUser(config, form, parentId),
            ]);
            ForwardNotificationService.addedToCollectionSuccess(parentId);
        }
        finally {
            this.isSubmitting = false;
        }
    };
    createCommentIfChangedByUser = async (config, form, parentId) => {
        const params = config.getCollectionReqData();
        if (params?.ids.length !== 1) {
            return;
        }
        const trimmedTitle = form.title.trim();
        const trimmedDescription = form.description.trim();
        const shouldSaveTitle = config.getForm().title.trim() !== trimmedTitle && trimmedTitle.length > 0;
        const shouldSaveDescription = config.getForm().description.trim() !== trimmedDescription && form.description.length > 0;
        const savedCommentData = [];
        if (shouldSaveTitle) {
            savedCommentData.push(`<p><bold>${trimmedTitle}</bold></p>`);
        }
        if (shouldSaveDescription) {
            savedCommentData.push(`<p>${trimmedDescription}</p>`);
        }
        if (savedCommentData.length > 0) {
            await this.commentsAPI.createCollectionComment(parentId, params.ids[0], savedCommentData.join(''));
        }
    };
}
__decorate([
    observable
], AddToCollectionModalStore.prototype, "isSubmitting", void 0);
export const useAddToCollectionModalStore = () => useStore(AddToCollectionModalStore);
export default AddToCollectionModalStore;
