import { isNumeric, pureStructure } from '@cutwise/utils';
import { EMPTY_VALUE_DASH } from '@/common/constants/app';
import { SPECTRUM_PAGE } from '@/common/constants/pages';
import { formatDate } from '@/common/helpers/formatDate';
import { ServiceOrderStatus } from '../constants/serviceOrderStatus';
export class ServiceOrderModel {
    status;
    links;
    name;
    uploadDate;
    expiresIn;
    weight;
    sum;
    service;
}
export class ServiceOrder extends ServiceOrderModel {
    constructor(model) {
        super();
        Object.keys(model).forEach((key) => {
            this[key] = model[key];
        });
    }
    get isExpired() {
        return this.status === ServiceOrderStatus.Completed && this.links.every((link) => link.expired);
    }
    get isProcessing() {
        return this.status === ServiceOrderStatus.Processing;
    }
    get date() {
        return this.uploadDate ? formatDate.toFullHumanDateTime(this.uploadDate) : EMPTY_VALUE_DASH;
    }
    get weightValue() {
        return this.weight ? `${this.weight}ct` : EMPTY_VALUE_DASH;
    }
    get appearance() {
        switch (this.status) {
            case ServiceOrderStatus.Expired:
            case ServiceOrderStatus.Failed:
                return 'tertiary';
            case ServiceOrderStatus.Processing:
                return 'neutral';
            case ServiceOrderStatus.ReadyForPayment:
                return 'secondary';
            default:
                return 'accent';
        }
    }
    get receiptLink() {
        return this.links?.find(({ type }) => type === 'receipt');
    }
    get actionLinks() {
        return (this.links
            .filter(({ type, expired }) => (type === 'action' || type === 'model') && !expired)
            .map((link) => {
            if (link.type === 'model' && isNumeric(link.modelId)) {
                link.href = `${SPECTRUM_PAGE}?p=${link.modelId}`;
            }
            return link;
        }) ?? []);
    }
    get modelLink() {
        return this.links?.find(({ type }) => type === 'model');
    }
    toJS() {
        return pureStructure({ ...this });
    }
}
