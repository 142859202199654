export class DriveItemsResponse {
    responses;
    constructor(content) {
        this.responses = content;
    }
    getAll() {
        return this.responses;
    }
    getErrors() {
        return this.responses.filter((item) => this.hasError(item)).map((item) => item.error);
    }
    getItems() {
        return this.responses.filter((item) => !this.hasError(item));
    }
    hasErrors() {
        return this.responses.some((item) => this.hasError(item));
    }
    hasError(item) {
        return 'error' in item && typeof item.error === 'string';
    }
}
