import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from 'react';
import ForwardDestinationList from '@/drive/drivePrototype/components/Forwarder/ForwardModal/ForwardDestinationList/ForwardDestinationList';
import ForwardModalActions from '../ForwardModalActions/ForwardModalActions';
import { ForwardModalTab } from './ForwardModalBody.types';
import styles from './ForwardModalBodySm.module.less';
import ForwardModalTabs from './ForwardModalTabs/ForwardModalTabs';
function ForwardModalBodySm(props) {
    const { config, onDestinationSelect, openDriveItemModal, openAddToCollectionModal, addToSpectrum, onQrClick } = props;
    const [activeTab, setActiveTab] = useState(ForwardModalTab.Actions);
    return (_jsxs(Fragment, { children: [_jsx(ForwardModalTabs, { activeTab: activeTab, onTabChange: setActiveTab }), _jsxs("div", { className: styles.content, children: [activeTab === ForwardModalTab.DestinationsList && (_jsx("div", { className: styles.listContainer, children: _jsx(ForwardDestinationList, { onSelect: onDestinationSelect }) })), activeTab === ForwardModalTab.Actions && (_jsx(ForwardModalActions, { config: config, openDriveItemModal: openDriveItemModal, openAddToCollectionModal: openAddToCollectionModal, addToSpectrum: addToSpectrum, onQrClick: onQrClick }))] })] }));
}
export default ForwardModalBodySm;
