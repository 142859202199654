import { convert } from 'html-to-text';
import { SCREEN_SM } from '@/common/constants/breakpoints';
import { isMarkupWithoutText } from '@/common/helpers/isMarkupWithoutText/isMarkupWithoutText';
import URLProvider from '@/common/services/URLProvider';
import { createMailUrl } from '@/common/utils/createMailUrl';
import { READABLE_TYPES } from '@/product/entity/Product';
export function createInfoText(config, form) {
    const url = config.getUrl();
    if (!url) {
        return '';
    }
    if (isMarkupWithoutText(form.description)) {
        return `${form.title}\n${url}`;
    }
    else {
        const plainTextDescription = convert(form.description, { wordwrap: false }).replace(/\n+/g, '\n');
        return `${form.title}\n${plainTextDescription}\n${url}`;
    }
}
export function createProductMailUrl(product, fromEmail) {
    const productType = READABLE_TYPES[product.type];
    const subject = `${productType} ${product.shortView} from cutwise.com`;
    const productUrl = URLProvider.convertRelativeToAbsolute(URLProvider.productDetailPage(product, null));
    const body = `Please take a look at ${productUrl}`;
    return createMailUrl({ from: fromEmail, subject, body });
}
export function isSmScreen(screenWidth) {
    return !!screenWidth && screenWidth < SCREEN_SM;
}
