import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react';
import Link, { NONE } from '@/common/components/Link/Link';
import MenuItem from '../../MenuList/MenuItem/MenuItem';
import MenuList from '../../MenuList/MenuList';
import Popover from '../../Popover/Popover';
function AboutPopover(props) {
    const { children, isOpened, close, onCollectionsClick, onPresentationClick, onPricingClick, onWorkshopClick, onPrivacyPolicyClick, } = props;
    return (_jsx(Popover, { body: (_jsxs(MenuList, { withRoundedCorners: true, children: [_jsx(MenuItem, { className: "show-on-small-screens", children: _jsx(Link, { activeClassName: "active", appearance: NONE, exact: true, onClick: onCollectionsClick, to: "/collections", children: "Demo" }) }), _jsx(MenuItem, { children: _jsx(Link, { appearance: NONE, external: true, href: "/pr/#/", onClick: onPresentationClick, children: "Technology Overview" }) }), _jsx(MenuItem, { children: _jsx(Link, { appearance: NONE, external: true, href: "https://octonus-teams.com/wiki/display/CUDO/Release+Notes", children: "What's new" }) }), _jsx(MenuItem, { children: _jsx(Link, { appearance: NONE, external: true, href: "https://octonus-teams.com/wiki/display/CUDO/Cutwise+Widget", children: "Embed Cutwise Widget" }) }), _jsx(MenuItem, { children: _jsx(Link, { appearance: NONE, external: true, href: "https://api-doc.cutwise.com/", children: "API Documentation" }) }), _jsx(MenuItem, { children: _jsx(Link, { appearance: NONE, external: true, href: "https://www.youtube.com/channel/UCgIrQJ25wIuLfCPbFcZ4S6Q", children: "YouTube Channel" }) }), _jsx(MenuItem, { children: _jsx(Link, { appearance: NONE, external: true, href: "https://docs.cutwise.com/", onClick: onWorkshopClick, children: "Performance Workshop" }) }), _jsx(MenuItem, { children: _jsx(Link, { appearance: NONE, external: true, href: "https://pricing.cutwise.com/", onClick: onPricingClick, children: "Pricing" }) }), _jsx(MenuItem, { children: _jsx(Link, { appearance: NONE, external: true, href: "/privacy", onClick: onPrivacyPolicyClick, children: "Privacy Policy" }) }), _jsx(MenuItem, { children: _jsx(Link, { appearance: NONE, external: true, href: "/about", children: "Contacts" }) })] })), className: "Popover--transparent", isOpen: isOpened, onOuterAction: close, preferPlace: "below", children: children }));
}
export default observer(AboutPopover);
