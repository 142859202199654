// extracted by mini-css-extract-plugin
var _1 = "DIk5AH0rGrKu3rV763Gk";
var _2 = "JAsUmkCgN68vg3bKRVzl";
var _3 = "#76F366";
var _4 = "#EB8632";
var _5 = "#ECC834";
var _6 = "#21FC87";
var _7 = "#EA4A30";
var _8 = "#C7EA45";
var _9 = "#272B2B";
var _a = "#BDBDBD";
var _b = "#7D7D7D";
var _c = "#6A0DAD";
var _d = "#3A65FF";
var _e = "#4A90E2";
var _f = "#ffffff";
var _10 = "Kc_G2bJ2ZriiezCRBN9P";
var _11 = "T8wk0KCi0F6Xo89aaLVC";
var _12 = "qdoEFVrr7J3cCf63zxjA";
var _13 = "JrltkLVbPdwGZMUbnOhH";
var _14 = "OaoriNUiY9Ay0Lh5x8pj";
var _15 = "SUoguPdD94RyaNyXenKQ";
var _16 = "1200px";
var _17 = "992px";
var _18 = "1366px";
var _19 = "768px";
var _1a = "1475px";
var _1b = "576px";
var _1c = "1600px";
var _1d = "320px";
var _1e = "2500px";
var _1f = "_R91tyimEYCkinafboke";
export { _1 as "base-link", _2 as "baseIcon", _3 as "colorGradeEX", _4 as "colorGradeFR", _5 as "colorGradeGD", _6 as "colorGradeOU", _7 as "colorGradePR", _8 as "colorGradeVG", _9 as "colorNeutral", _a as "colorNeutralLighest", _b as "colorNeutralLight", _c as "colorNonOwnerSweetLine", _d as "colorOwnerSweetLine", _e as "colorRegularLinks", _f as "colorWhite", _10 as "content", _11 as "listContainer", _12 as "modal", _13 as "product-block-grid", _14 as "product-block-subgrid", _15 as "reset-button", _16 as "screenlg", _17 as "screenmd", _18 as "screenml", _19 as "screensm", _1a as "screenxlg", _1b as "screenxs", _1c as "screenxxlg", _1d as "screenxxs", _1e as "screenxxxlg", _1f as "single-page-responsive-font-size" }
export default { "base-link": _1, "baseIcon": _2, "colorGradeEX": _3, "colorGradeFR": _4, "colorGradeGD": _5, "colorGradeOU": _6, "colorGradePR": _7, "colorGradeVG": _8, "colorNeutral": _9, "colorNeutralLighest": _a, "colorNeutralLight": _b, "colorNonOwnerSweetLine": _c, "colorOwnerSweetLine": _d, "colorRegularLinks": _e, "colorWhite": _f, "content": _10, "listContainer": _11, "modal": _12, "product-block-grid": _13, "product-block-subgrid": _14, "reset-button": _15, "screenlg": _16, "screenmd": _17, "screenml": _18, "screensm": _19, "screenxlg": _1a, "screenxs": _1b, "screenxxlg": _1c, "screenxxs": _1d, "screenxxxlg": _1e, "single-page-responsive-font-size": _1f }
