// extracted by mini-css-extract-plugin
var _1 = "lPn5JcELxyftBjzooxd5";
var _2 = "GYqMz3Ap3W2UyoWU1wOH";
var _3 = "#76F366";
var _4 = "#EB8632";
var _5 = "#ECC834";
var _6 = "#21FC87";
var _7 = "#EA4A30";
var _8 = "#C7EA45";
var _9 = "#272B2B";
var _a = "#BDBDBD";
var _b = "#7D7D7D";
var _c = "#6A0DAD";
var _d = "#3A65FF";
var _e = "#4A90E2";
var _f = "#ffffff";
var _10 = "ueSjSPN2wUNwmlCiVYrq";
var _11 = "pYzGAmPN3JRocK0mvOyM";
var _12 = "WQHmSoEskBYVfdNMGp1W";
var _13 = "dCHGKeWSF0AUxEVme9Ia";
var _14 = "Xk_EhNbxB6afb_hwREeh";
var _15 = "1200px";
var _16 = "992px";
var _17 = "1366px";
var _18 = "768px";
var _19 = "1475px";
var _1a = "576px";
var _1b = "1600px";
var _1c = "320px";
var _1d = "2500px";
var _1e = "eyRm1F1a2ITEbagxUN9A";
var _1f = "cab3L3EkmxiDjthAcCrE";
export { _1 as "base-link", _2 as "button", _3 as "colorGradeEX", _4 as "colorGradeFR", _5 as "colorGradeGD", _6 as "colorGradeOU", _7 as "colorGradePR", _8 as "colorGradeVG", _9 as "colorNeutral", _a as "colorNeutralLighest", _b as "colorNeutralLight", _c as "colorNonOwnerSweetLine", _d as "colorOwnerSweetLine", _e as "colorRegularLinks", _f as "colorWhite", _10 as "driveItemModalFooter", _11 as "product-block-grid", _12 as "product-block-subgrid", _13 as "reset-button", _14 as "rightGroup", _15 as "screenlg", _16 as "screenmd", _17 as "screenml", _18 as "screensm", _19 as "screenxlg", _1a as "screenxs", _1b as "screenxxlg", _1c as "screenxxs", _1d as "screenxxxlg", _1e as "single-page-responsive-font-size", _1f as "withAddButton" }
export default { "base-link": _1, "button": _2, "colorGradeEX": _3, "colorGradeFR": _4, "colorGradeGD": _5, "colorGradeOU": _6, "colorGradePR": _7, "colorGradeVG": _8, "colorNeutral": _9, "colorNeutralLighest": _a, "colorNeutralLight": _b, "colorNonOwnerSweetLine": _c, "colorOwnerSweetLine": _d, "colorRegularLinks": _e, "colorWhite": _f, "driveItemModalFooter": _10, "product-block-grid": _11, "product-block-subgrid": _12, "reset-button": _13, "rightGroup": _14, "screenlg": _15, "screenmd": _16, "screenml": _17, "screensm": _18, "screenxlg": _19, "screenxs": _1a, "screenxxlg": _1b, "screenxxs": _1c, "screenxxxlg": _1d, "single-page-responsive-font-size": _1e, "withAddButton": _1f }
