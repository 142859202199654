import isArray from 'lodash/isArray';
import Storage, { KEY_FORWARD_DESTINATIONS } from '@/common/services/Storage';
import { getUniqueDestinationId } from '@/drive/drivePrototype/entities/ForwardDestinationListData';
class ForwardDestinationStorage {
    static get() {
        const value = Storage.getObject(KEY_FORWARD_DESTINATIONS, Storage.STORAGE_LOCAL);
        return isArray(value) ? value : [];
    }
    static add(destination) {
        const data = this.get().filter((d) => getUniqueDestinationId(d) !== getUniqueDestinationId(destination));
        const storageLimit = 500;
        const newData = [destination, ...data].slice(0, storageLimit);
        Storage.saveObject(KEY_FORWARD_DESTINATIONS, newData, Storage.STORAGE_LOCAL);
    }
}
export default ForwardDestinationStorage;
