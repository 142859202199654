import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { observer } from 'mobx-react';
import DriveItemModalFooter from '@/drive/components/DriveItemModalFooter/DriveItemModalFooter';
import { TreeItemModal } from '@/drive/components/TreeItemModal/TreeItemModal';
import DriveItemModalForm from '@/drive/drivePrototype/components/DriveItemModal/DriveItemModalForm/DriveItemModalForm';
import { useDriveItemModalForm } from '@/drive/drivePrototype/components/DriveItemModal/useDriveItemModalForm';
import DriveItemPreview from '@/drive/drivePrototype/components/DriveItemPreview/DriveItemPreview';
import { MediaSize } from '@/media/constants/sizeCodes';
import styles from './DriveItemFormModal.module.less';
function DriveItemFormModal(props) {
    const { form, preview, title, onClose, onSubmit, urlEnabled, usePortal } = props;
    const { values, errors, handleSubmit, setFieldValue } = useDriveItemModalForm(async (formValues) => {
        onClose?.();
        onSubmit?.(formValues);
    }, form, urlEnabled);
    return (_jsx(TreeItemModal, { usePortal: usePortal, isShowed: !!form, onClose: onClose, title: title, treeShown: false, previewShown: false, formContent: (_jsxs(Fragment, { children: [preview && _jsx(DriveItemPreview, { preview: preview, mediaSize: MediaSize.Small }), _jsx(DriveItemModalForm, { className: styles.form, values: values, errors: errors, setFieldValue: setFieldValue, urlEnabled: urlEnabled, onSubmit: handleSubmit, onReset: onClose })] })), footerContent: _jsx(DriveItemModalFooter, {}) }));
}
export default observer(DriveItemFormModal);
