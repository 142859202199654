import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import { useAppStore } from '@/common/stores/AppStore';
import { isSmScreen } from '@/drive/drivePrototype/components/Forwarder/ForwardModal/ForwardModal.utils';
import styles from './SocialActionContent.module.less';
function SocialActionContent(props) {
    const { children, title } = props;
    const { screenWidth } = useAppStore();
    return (_jsxs("div", { className: styles.socialActionContent, children: [_jsx("div", { className: styles.icon, children: children }), isSmScreen(screenWidth) && title] }));
}
export default observer(SocialActionContent);
