import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import cls from 'classnames';
import Icon from '@/common/components/Icon/Icon';
import EmptyTreePlaceholder from '@/drive/components/EmptyTreePlaceholder/EmptyTreePlaceholder';
import { SortableTree } from '@/drive/components/SortableTree/SortableTree';
import TreeItem from '@/drive/components/TreeItem/TreeItem';
import { useDriveItemModalTreeStore } from '@/drive/drivePrototype/components/DriveItemModal/DriveItemModalTree/DriveItemModalTreeStore';
import { DriveSection } from '@/drive/drivePrototype/types/driveSection';
import { flattenTree } from '@/drive/drivePrototype/utils/DriveTree.utils';
import styles from './DriveItemModalTree.module.less';
function DriveItemModalTree() {
    const { tree, filteredTree, selectedId, setSelectedId, toggleCollapse, isPendingCreation, finishCreation, editTitle, newItem, } = useDriveItemModalTreeStore();
    if (!tree) {
        return null;
    }
    if (!filteredTree || filteredTree.length === 0) {
        return _jsx(EmptyTreePlaceholder, { isTreeFilteredOut: tree.length > 0 });
    }
    return (_jsx(SortableTree, { items: filteredTree, selectedId: selectedId, onSelect: (item) => setSelectedId(item.id), onCollapse: toggleCollapse, flattenTree: flattenTree, renderItem: (item) => (_jsx(TreeItem, { isEdited: item.id === newItem?.id, value: item.id === newItem?.id ? newItem.title : item.title, isSelected: item.id === selectedId, onChange: editTitle, save: finishCreation, isPendingCreation: isPendingCreation, icon: item.id === DriveSection.MyDrive ? (_jsx(Icon, { name: "cutwise-drive", className: styles.icon })) : (_jsx(Icon, { name: "folder", className: cls(styles.icon, styles.folder) })) })) }));
}
export default observer(DriveItemModalTree);
