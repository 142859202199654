import { jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react';
import Button, { TRANSPARENT } from '@/common/components/Button/Button';
import { useAppStore } from '@/common/stores/AppStore';
import { isSmScreen } from '@/drive/drivePrototype/components/Forwarder/ForwardModal/ForwardModal.utils';
import styles from './ForwardModalAction.module.less';
function ForwardModalAction(props) {
    const { onClick, title, children, compactOnMd, href } = props;
    const { screenWidth } = useAppStore();
    const isCompact = compactOnMd && !isSmScreen(screenWidth);
    return (_jsxs(Button, { color: TRANSPARENT, className: styles.action, onClick: onClick, href: href, iconPosition: !isCompact ? 'left' : undefined, withIcon: !!isCompact, children: [children, !isCompact && title] }));
}
export default observer(ForwardModalAction);
